<template>
  <div>
    <div class="main-container container">
      <ul class="breadcrumb">
        <li><router-link to="/"><i class="fa fa-home"></i></router-link></li>
        <li><router-link to="/">상품</router-link></li>
        <li>{{productsName}}</li>
      </ul>

      <div class="row">


        <!--Middle Part Start-->
        <div id="content" class="col-md-9 col-sm-8">

          <div class="product-view">
            <div class="left-content-product">
              <div class="row">
                <div class="content-product-left col-md-6 col-sm-12 col-xs-12">
                  <div id="thumb-slider-vertical" class="thumb-vertical-outer">
                    <!-- <span class="btn-more prev-thumb nt"><i class="fa fa-angle-up"></i></span>
                    <span class="btn-more next-thumb nt"><i class="fa fa-angle-down"></i></span> -->
                    <ul class="thumb-vertical">
<!--                      <li class="owl2-item" @click="visibleThisImage(0)">-->
<!--                        <a data-index="0" class="img thumbnail" data-image="image/catalog/demo/product/funiture/1.jpg" :title="productsName">-->
<!--                          <img :src="imgOne" :title="productsName" >-->
<!--                        </a>-->
<!--                      </li>-->
<!--                      <li class="owl2-item"  @click="visibleThisImage(1)">-->
<!--                        <a data-index="1" class="img thumbnail " data-image="image/catalog/demo/product/funiture/2.jpg" :title="productsName">-->
<!--                          <img :src="imgTwo" :title="productsName" >-->
<!--                        </a>-->
<!--                      </li>-->
<!--                      <li class="owl2-item">-->
<!--                        <a data-index="2" class="img thumbnail" data-image="image/catalog/demo/product/funiture/3.jpg" :title="productsName">-->
<!--                          <img src="../../assets/image/catalog/demo/product/funiture/3.jpg" :title="productsName" >-->
<!--                        </a>-->
<!--                      </li>-->
<!--                      <li class="owl2-item">-->
<!--                        <a data-index="3" class="img thumbnail" data-image="image/catalog/demo/product/funiture/4.jpg" :title="productsName">-->
<!--                          <img src="../../assets/image/catalog/demo/product/funiture/4.jpg" :title="productsName" >-->
<!--                        </a>-->
<!--                      </li>-->
<!--                      <li class="owl2-item">-->
<!--                        <a data-index="3" class="img thumbnail" data-image="image/catalog/demo/product/funiture/5.jpg" :title="productsName">-->
<!--                          <img src="../../assets/image/catalog/demo/product/funiture/5.jpg" :title="productsName">-->
<!--                        </a>-->
<!--                      </li>-->
                    </ul>


                  </div>
                  <div class="large-image  vertical">
                    <img itemprop="image" class="product-image-zoom" :src="imagePath(images, imageList)" data-zoom-image="image/catalog/demo/product/funiture/1.jpg" :title="productsName" style="width: 600px; height: 600px;">
                  </div>
<!--                  <a class="thumb-video pull-left" href="https://www.youtube.com/watch?v=HhabgvIIXik"><i class="fa fa-youtube-play"></i></a>-->

                </div>

                <div class="content-product-right col-md-6 col-sm-12 col-xs-12">
                  <div class="title-product">
                    <h1>{{productsName}}</h1>
                    <span>등록일 : 2020-01-01</span>
                  </div>
                  <!-- Review ---->
                  <div class="box-review form-group">
<!--                    <div class="ratings">-->
<!--                      <div class="rating-box">-->
<!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <a class="reviews_button" href="" onclick="$('a[href=\'#tab-review\']').trigger('click'); return false;">0 reviews</a>	|-->
<!--                    <a class="write_review_button" href="" onclick="$('a[href=\'#tab-review\']').trigger('click'); return false;">Write a review</a>-->
                  </div>

                  <div class="product-label form-group">
                    <div class="product_page_price price" itemprop="offerDetails" itemscope="" itemtype="http://data-vocabulary.org/Offer">
<!--                      <span class="price-new" itemprop="price"><i class="fa fa-won"></i> {{sellPrice}}</span>-->
<!--                      <span class="price-old"><i class="fa fa-won"></i> {{defaultPrice}}</span>-->
                    </div>
<!--                    <div class="stock"><span>재고:</span> <span class="status-stock">가능</span></div>-->
                  </div>

                  <div class="product-box-desc">
                    <div class="inner-box-desc">
                      <div class="price-tax">{{ productsText}}</div>
                    </div>
                  </div>

<!--                  <div class="short_description form-group">-->
<!--                    <h4></h4>-->
<!--                    The 30-inch Apple Cinema HD Display delivers an amazing 2560 x 1600 pixel resolution. Designed specifically for the creative professional, this display provid...-->
<!--                  </div>-->
<!--                  <div id="product">-->
<!--                    <h4>Available Options</h4>-->
<!--                    <div class="image_option_type form-group required">-->
<!--                      <label class="control-label">Colors</label>-->
<!--                      <ul class="product-options clearfix" id="input-option231">-->
<!--                        <li class="radio active">-->
<!--                          <label>-->
<!--                            <input class="image_radio" type="radio" name="option[231]" value="33"> <img src="../../assets/image/demo/colors/blue.jpg"-->
<!--                                                                                                        data-original-title="blue +$12.00" class="img-thumbnail icon icon-color">				<i class="fa fa-check"></i>-->
<!--                            <label> </label>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li class="radio">-->
<!--                          <label>-->
<!--                            <input class="image_radio" type="radio" name="option[231]" value="34"> <img src="../../assets/image/demo/colors/brown.jpg"-->
<!--                                                                                                        data-original-title="brown -$12.00" class="img-thumbnail icon icon-color">				<i class="fa fa-check"></i>-->
<!--                            <label> </label>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li class="radio">-->
<!--                          <label>-->
<!--                            <input class="image_radio" type="radio" name="option[231]" value="35"> <img src="../../assets/image/demo/colors/green.jpg"-->
<!--                                                                                                        data-original-title="green +$12.00" class="img-thumbnail icon icon-color">				<i class="fa fa-check"></i>-->
<!--                            <label> </label>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li class="selected-option">-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->



<!--                    <div class="form-group box-info-product">-->
<!--                      <div class="option quantity">-->
<!--                        <div class="input-group quantity-control" unselectable="on" style="-webkit-user-select: none;">-->
<!--                          <label>Qty</label>-->
<!--                          <input class="form-control" type="text" name="quantity"-->
<!--                                 value="1">-->
<!--                          <input type="hidden" name="product_id" value="50">-->
<!--                          <span class="input-group-addon product_quantity_down">−</span>-->
<!--                          <span class="input-group-addon product_quantity_up">+</span>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="cart">-->
<!--                        <input type="button" data-toggle="tooltip" title="" value="Add to Cart" data-loading-text="Loading..." id="button-cart" class="btn btn-mega btn-lg" onclick="cart.add('42', '1');" data-original-title="Add to Cart">-->
<!--                      </div>-->
<!--                      <div class="add-to-links wish_comp">-->
<!--                        <ul class="blank list-inline">-->
<!--                          <li class="wishlist">-->
<!--                            <a class="icon" data-toggle="tooltip" title=""-->
<!--                               onclick="wishlist.add('50');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>-->
<!--                            </a>-->
<!--                          </li>-->
<!--                          <li class="compare">-->
<!--                            <a class="icon" data-toggle="tooltip" title=""-->
<!--                               onclick="compare.add('50');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>-->
<!--                            </a>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->

<!--                    </div>-->

<!--                  </div>-->
                  <!-- end box info product -->

                </div>
              </div>
            </div>


          </div>

          <!-- Product Tabs -->
<!--          <div class="producttab ">-->
<!--            <div class="tabsslider horizontal-tabs  col-xs-12">-->
<!--              <ul class="nav nav-tabs">-->
<!--                <li class="active"><a data-toggle="tab" href="#tab-1">상세 설명</a></li>-->
<!--&lt;!&ndash;                <li class="item_nonactive"><a data-toggle="tab" href="#tab-review">Reviews (1)</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li class="item_nonactive"><a data-toggle="tab" href="#tab-4">Tags</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li class="item_nonactive"><a data-toggle="tab" href="#tab-5">Custom Tab</a></li>&ndash;&gt;-->
<!--              </ul>-->
<!--              <div class="tab-content col-xs-12">-->
<!--                <div id="tab-1" class="tab-pane fade active in">-->
<!--                  <p>{{productsText}} </p>-->


<!--                </div>-->
<!--                <div id="tab-review" class="tab-pane fade">-->
<!--                  <form>-->
<!--                    <div id="review">-->
<!--                      <table class="table table-striped table-bordered">-->
<!--                        <tbody>-->
<!--                        <tr>-->
<!--                          <td style="width: 50%;"><strong>Super Administrator</strong></td>-->
<!--                          <td class="text-right">29/07/2015</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td colspan="2">-->
<!--                            <p>Best this product opencart</p>-->
<!--                            <div class="ratings">-->
<!--                              <div class="rating-box">-->
<!--                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                                <span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                                <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                      </table>-->
<!--                      <div class="text-right"></div>-->
<!--                    </div>-->
<!--                    <h2 id="review-title">Write a review</h2>-->
<!--                    <div class="contacts-form">-->
<!--                      <div class="form-group"> <span class="icon icon-user"></span>-->
<!--                        <input type="text" name="name" class="form-control" value="Your Name" onblur="if (this.value == '') {this.value = 'Your Name';}" onfocus="if(this.value == 'Your Name') {this.value = '';}">-->
<!--                      </div>-->
<!--                      <div class="form-group"> <span class="icon icon-bubbles-2"></span>-->
<!--                        <textarea class="form-control" name="text" onblur="if (this.value == '') {this.value = 'Your Review';}" onfocus="if(this.value == 'Your Review') {this.value = '';}">Your Review</textarea>-->
<!--                      </div>-->
<!--                      <span style="font-size: 11px;"><span class="text-danger">Note:</span>						HTML is not translated!</span>-->

<!--                      <div class="form-group">-->
<!--                        <b>Rating</b> <span>Bad</span>&nbsp;-->
<!--                        <input type="radio" name="rating" value="1"> &nbsp;-->
<!--                        <input type="radio" name="rating"-->
<!--                               value="2"> &nbsp;-->
<!--                        <input type="radio" name="rating"-->
<!--                               value="3"> &nbsp;-->
<!--                        <input type="radio" name="rating"-->
<!--                               value="4"> &nbsp;-->
<!--                        <input type="radio" name="rating"-->
<!--                               value="5"> &nbsp;<span>Good</span>-->

<!--                      </div>-->
<!--                      <div class="buttons clearfix"><a id="button-review" class="btn buttonGray">Continue</a></div>-->
<!--                    </div>-->
<!--                  </form>-->
<!--                </div>-->
<!--                <div id="tab-4" class="tab-pane fade">-->
<!--                  <a href="#">Monitor</a>,-->
<!--                  <a href="#">Apple</a>-->
<!--                </div>-->
<!--                <div id="tab-5" class="tab-pane fade">-->
<!--                  <p>Lorem ipsum dolor sit amet, consetetur-->
<!--                    sadipscing elitr, sed diam nonumy eirmod-->
<!--                    tempor invidunt ut labore et dolore-->
<!--                    magna aliquyam erat, sed diam voluptua.-->
<!--                    At vero eos et accusam et justo duo-->
<!--                    dolores et ea rebum. Stet clita kasd-->
<!--                    gubergren, no sea takimata sanctus est-->
<!--                    Lorem ipsum dolor sit amet. Lorem ipsum-->
<!--                    dolor sit amet, consetetur sadipscing-->
<!--                    elitr, sed diam nonumy eirmod tempor-->
<!--                    invidunt ut labore et dolore magna aliquyam-->
<!--                    erat, sed diam voluptua. </p>-->
<!--                  <p>At vero eos et accusam et justo duo dolores-->
<!--                    et ea rebum. Stet clita kasd gubergren,-->
<!--                    no sea takimata sanctus est Lorem ipsum-->
<!--                    dolor sit amet. Lorem ipsum dolor sit-->
<!--                    amet, consetetur sadipscing elitr.</p>-->
<!--                  <p>Sed diam nonumy eirmod tempor invidunt-->
<!--                    ut labore et dolore magna aliquyam erat,-->
<!--                    sed diam voluptua. At vero eos et accusam-->
<!--                    et justo duo dolores et ea rebum. Stet-->
<!--                    clita kasd gubergren, no sea takimata-->
<!--                    sanctus est Lorem ipsum dolor sit amet.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <!-- //Product Tabs -->






        </div>
        <!--Middle Part End-->
        <!--Left Part Start -->
        <aside class="col-sm-4 col-md-3 content-aside" id="column-left">
          <div class="module category-style">
            <h3 class="modtitle">카테고리</h3>
            <div class="modcontent">
              <div class="box-category">
                <ul id="cat_accordion" class="list-group">
                  <li class="hadchild" v-for="(category, index) in $store.getters['productsStore/categoryList'].categoryList" :key="index"> <button type="button" class="btn" style="background: white; color: #f4a137; font-weight: bold;" v-if="catalogList.indexOf(category.commonCode) > -1">{{ category.commonName }} </button><button type="button" class="btn" style="background: white;" v-if="catalogList.indexOf(category.commonCode)== -1">{{ category.commonName }} </button></li>

                </ul>
              </div>


            </div>
          </div>
<!--          <div class="module product-simple">-->
<!--            <h3 class="modtitle">-->
<!--              <span>Latest products</span>-->
<!--            </h3>-->
<!--            <div class="modcontent">-->
<!--              <div class="extraslider" >-->
<!--                &lt;!&ndash; Begin extraslider-inner &ndash;&gt;-->
<!--                <div class="extraslider-inner">-->
<!--                  <div class="item ">-->
<!--                    <div class="product-layout item-inner style1 ">-->
<!--                      <div class="item-image">-->
<!--                        <div class="item-img-info">-->
<!--                          <a href="#" target="_self" title="Mandouille short ">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/8.jpg" alt="Mandouille short">-->
<!--                          </a>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      <div class="item-info">-->
<!--                        <div class="item-title">-->
<!--                          <a href="#" target="_self" title="Mandouille short">Mandouille short </a>-->
<!--                        </div>-->
<!--                        <div class="rating">-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                        </div>-->
<!--                        <div class="content_price price">-->
<!--                          <span class="price-new product-price">$55.00 </span>&nbsp;&nbsp;-->

<!--                          <span class="price-old">$76.00 </span>&nbsp;-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; End item-info &ndash;&gt;-->
<!--                      &lt;!&ndash; End item-wrap-inner &ndash;&gt;-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End item-wrap &ndash;&gt;-->
<!--                    <div class="product-layout item-inner style1 ">-->
<!--                      <div class="item-image">-->
<!--                        <div class="item-img-info">-->
<!--                          <a href="#" target="_self" title="Xancetta bresao ">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/7.jpg" alt="Xancetta bresao">-->
<!--                          </a>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      <div class="item-info">-->
<!--                        <div class="item-title">-->
<!--                          <a href="#" target="_self" title="Xancetta bresao">-->
<!--                            Xancetta bresao-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="rating">-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                        </div>-->
<!--                        <div class="content_price price">-->
<!--                          <span class="price-new product-price">$80.00 </span>&nbsp;&nbsp;-->

<!--                          <span class="price-old">$89.00 </span>&nbsp;-->



<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; End item-info &ndash;&gt;-->
<!--                      &lt;!&ndash; End item-wrap-inner &ndash;&gt;-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End item-wrap &ndash;&gt;-->
<!--                    <div class="product-layout item-inner style1 ">-->
<!--                      <div class="item-image">-->
<!--                        <div class="item-img-info">-->
<!--                          <a href="#" target="_self" title="Sausage cowbee ">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/6.jpg" alt="Sausage cowbee">-->
<!--                          </a>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      <div class="item-info">-->
<!--                        <div class="item-title">-->
<!--                          <a href="#" target="_self" title="Sausage cowbee">-->
<!--                            Sausage cowbee-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="rating">-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                        </div>-->

<!--                        <div class="content_price price">-->
<!--                                                <span class="price product-price">-->
<!--                                                                $66.00-->
<!--                                                            </span>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; End item-info &ndash;&gt;-->
<!--                      &lt;!&ndash; End item-wrap-inner &ndash;&gt;-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End item-wrap &ndash;&gt;-->
<!--                    <div class="product-layout item-inner style1 ">-->
<!--                      <div class="item-image">-->
<!--                        <div class="item-img-info">-->
<!--                          <a href="#" target="_self" title="Chicken swinesha ">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/5.jpg" alt="Chicken swinesha">-->
<!--                          </a>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      <div class="item-info">-->
<!--                        <div class="item-title">-->
<!--                          <a href="#" target="_self" title="Chicken swinesha">-->
<!--                            Chicken swinesha-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="rating">-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                        </div>-->
<!--                        <div class="content_price price">-->
<!--                          <span class="price-new product-price">$45.00 </span>&nbsp;&nbsp;-->

<!--                          <span class="price-old">$56.00 </span>&nbsp;-->



<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; End item-info &ndash;&gt;-->
<!--                      &lt;!&ndash; End item-wrap-inner &ndash;&gt;-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End item-wrap &ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;End extraslider-inner &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="module banner-left hidden-xs ">-->
<!--            <div class="banner-sidebar banners">-->
<!--              <div>-->
<!--                <a title="Banner Image" href="#">-->
<!--                  <img src="../../assets/image/catalog/banners/banner-sidebar.jpg" alt="Banner Image">-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </aside>
        <!--Left Part End -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: 'MainProductsDetailPage',
  data(){
    return {
      productsName : null,
      defaultPrice : null,
      sellPrice : null,
      insertDt : null,
      imgOne : null,
      imgTwo : null,
      showImg : null,
      productsText : null,
      catalogList : null,
      images : null,
      imageList : null,
    }
  },
  computed : {
    ...mapGetters([
      'getCategories'
    ]),
  },
  methods : {
    visibleThisImage(value){
      if(value == 0){
        this.showImg = this.imgOne
      } else if(value == 1){
        this.showImg = this.imgTwo
      }

    },
    imagePath(value, list){
      console.log("main best seller page value", value, list);

      if(value != undefined && value.length > 2){
        return this.siteUrl + "/common/showImage?code=" + value[0].code + "&seq=5";
      }
      if(list != undefined && list != null){
        return "http://pang.frients.ai/modupang/" + list;


      } else {
        return "/assets/images/catalog/demo/product/index2/8.jpg";
      }

    },
  },
  created() {
    console.log('MainProductsDetailPage.created');

    $('html, body').animate({scrollTop:0},400);

  },
  mounted() {
    console.log('MainProductsDetailPage.mounted', this.$route.params.productsCode);




    var param = {
      productCode : this.$route.params.productsCode
    }


    this.api.productEach(param)
      .then((res)=> {

        if (this.utils.preApiHandle(res)) {
          console.log("util : ", res);
        }

        console.log(res)

        //
        this.productsName = res.product.productsName
        this.productsText = res.product.productsText
        this.defaultPrice = res.product.defaultPrice
        this.stockAmount = res.product.stockAmount
        this.sellPrice = res.product.sellPrice
        this.catalogList = res.product.categList


        console.log("dldldldl",res.product.imgList)

        this.images =res.product.images;
        this.imageList = res.product.imgList;






          // if(split.length == ){
          //
          //   this.$store.dispatch("emptyImageData", {imageOne : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/" + split[0] , imageTwo :"https://modupang-products.s3.ap-northeast-2.amazonaws.com/" + split[1], imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[2]})
          // } else if(split.length == 2){
          //   this.$store.dispatch("emptyImageData", {imageOne : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[0] , imageTwo : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[1], imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg"})
          //
          // } else if(split.length == 1){
          //   this.$store.dispatch("emptyImageData", {imageOne :"https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[0] , imageTwo : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg", imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg"})
          //
          // }


        //
        //
        // if (res.product.discountPercent == "0") {
        //
        //   this.discountType = 1
        //   this.discountMoney = res.product.discountPrice
        //
        // } else if (res.product.discountPrice == "0") {
        //
        //   this.discountType = 2
        //   this.discountMoney = res.product.discountPercent
        // }
        //
        // this.link = res.product.link
        //
        // if (res.product.categList != null) {
        //   console.log(JSON.parse(res.product.categList).length)
        //
        //   for (var i = 0; i < JSON.parse(res.product.categList).length; i++) {
        //     this.categoryCheck.push(JSON.parse(res.product.categList)[i])
        //
        //   }


      })

          //
          // if(res.product.imgList != null){
          //   var split = res.product.imgList.split(",");
          //
          //
          //   if(split.length == 3){
          //
          //     this.$store.dispatch("emptyImageData", {imageOne : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/" + split[0] , imageTwo :"https://modupang-products.s3.ap-northeast-2.amazonaws.com/" + split[1], imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[2]})
          //   } else if(split.length == 2){
          //     this.$store.dispatch("emptyImageData", {imageOne : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[0] , imageTwo : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[1], imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg"})
          //
          //   } else if(split.length == 1){
          //     this.$store.dispatch("emptyImageData", {imageOne :"https://modupang-products.s3.ap-northeast-2.amazonaws.com/"+ split[0] , imageTwo : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg", imageThree : "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/cate270.jpg"})
          //
          //   }
          //
          //
          //
          //
          //
          // }


























  },
  beforeDestroy() {
    console.log('MainProductsDetailPage.beforeDestroy');
  },
};
</script>
