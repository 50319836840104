import { payModalStoreActions } from './payModalStoreActions';
export const payModalStore = {
  namespaced: true,
  state: {
    visible: '', // null
    price: '', // null
  },
  getters: {
    visible: state => {
      if (state.visible === undefined || state.visible === null || state.visible === '') {
        const jsonStr = localStorage.getItem('visible');
        if (jsonStr === undefined || jsonStr === null) {
          state.visible = null;
          return state.visible;
        } else {
          state.visible = JSON.parse(jsonStr);
        }
      }
      return state.visible;
    },
    price: state => {
      if (state.price === undefined || state.price === null || state.price === '') {
        const jsonStr = localStorage.getItem('price');
        if (jsonStr === undefined || jsonStr === null) {
          state.price = null;
          return state.price;
        } else {
          state.price = JSON.parse(jsonStr);
        }
      }
      return state.price;
    },
  },
  mutations: {
    visible: (state, visible) => {
      if (visible === undefined || visible === null) {
        localStorage.removeItem('visible');
      } else {
        localStorage.setItem('visible', JSON.stringify(visible));
      }
      state.visible = visible;
    },
    price: (state, price) => {
      if (price === undefined || price === null) {
        localStorage.removeItem('price');
      } else {
        localStorage.setItem('price', JSON.stringify(price));
      }
      state.price = price;
    },
  },
  actions: payModalStoreActions,
};
