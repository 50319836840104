<template>
  <div>
<!--    <div>{{getCompanyData}}</div>-->


    <!-- Header Top -->
    <div class="header-top hidden-compact">
      <div class="container">
        <div class="row">
          <div class="header-top-left col-lg-6 col-md-8 col-sm-6 col-xs-4">
            <div class="hidden-sm hidden-xs welcome-msg"><b>모두팡에 오신 것을 환영합니다 !</b> 무료 쿠폰과 신규 오퍼를 확인하세요.</div>
            <ul class="top-link list-inline hidden-lg hidden-md">
              <li class="account " :class="{'open' : accountOpen}" id="my_account" >
                <button type="button"  title="My Account " style="border: none; background: transparent;" class="btn-xs " data-toggle="dropdown " @click="openAccount"> <span class="hidden-xs">My Account </span>  <span class="fa fa-caret-down"></span>
                </button >
                <ul class=" " id="account-dropdown-id" style="display: none;">
                  <li v-if="$store.getters['loginStore/companyCode'] == null || $store.getters['loginStore/companyCode'].companyCode == null" @click="hideMenus"><router-link to="/signIn"><i class="fa fa-user"></i> 회원가입</router-link></li>
                  <li  v-if="$store.getters['loginStore/companyCode'] == null || $store.getters['loginStore/companyCode'].companyCode == null"  @click="hideMenus"><router-link to="/login"><i class="fa fa-pencil-square-o"></i> 로그인</router-link></li>
                  <li  v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null"  @click="logout"><i class="fa fa-pencil-square-o"></i> 로그아웃</li>
                </ul>
              </li>

            </ul>
          </div>
          <div class="header-top-right collapsed-block col-lg-6 col-md-4 col-sm-6 col-xs-8">
            <ul class="top-link list-inline lang-curr">
              <!--                <li class="currency">-->
              <!--                  <div class="btn-group currencies-block">-->
              <!--                    <form action="index.html" method="post" enctype="multipart/form-data" id="currency">-->
              <!--                      <a class="btn btn-link dropdown-toggle" data-toggle="dropdown">-->
              <!--                        <span class="icon icon-credit "></span> $ US Dollar  <span class="fa fa-angle-down"></span>-->
              <!--                      </a>-->
              <!--                      <ul class="dropdown-menu btn-xs">-->
              <!--                        <li> <a href="#">(€)&nbsp;Euro</a></li>-->
              <!--                        <li> <a href="#">(£)&nbsp;Pounds    </a></li>-->
              <!--                        <li> <a href="#">($)&nbsp;US Dollar </a></li>-->
              <!--                      </ul>-->
              <!--                    </form>-->
              <!--                  </div>-->
              <!--                </li>-->
              <!--                <li class="language">-->
              <!--                  <div class="btn-group languages-block ">-->
              <!--                    <form action="index.html" method="post" enctype="multipart/form-data" id="bt-language">-->
              <!--                      <a class="btn btn-link dropdown-toggle" data-toggle="dropdown">-->
              <!--                        <img src="../assets/image/catalog/flags/gb.png" alt="English" title="English">-->
              <!--                        <span class="">English</span>-->
              <!--                        <span class="fa fa-angle-down"></span>-->
              <!--                      </a>-->
              <!--                      <ul class="dropdown-menu">-->
              <!--                        <li><a href="index.html"><img class="image_flag" src="../assets/image/catalog/flags/gb.png" alt="English" title="English" /> English </a></li>-->
              <!--                        <li> <a href="index.html"> <img class="image_flag" src="../assets/image/catalog/flags/ar.png" alt="Arabic" title="Arabic" /> Arabic </a> </li>-->
              <!--                      </ul>-->
              <!--                    </form>-->
              <!--                  </div>-->

              <!--                </li>-->
            </ul>



          </div>
        </div>
      </div>
    </div>
    <!-- //Header Top -->

    <!-- Header center -->
    <div class="header-middle">
      <div class="container">
        <div class="row">
          <!-- Logo -->
          <div class="navbar-logo col-lg-2 col-md-3 col-sm-12 col-xs-12">
            <div class="logo" @click="toMainLogo">
             <img src="../../assets/image/logo/orange_size.png" title="모두팡" alt="모두팡" style="width: 140px;" /></div>
          </div>
          <!-- //end Logo -->
          <!-- Search -->
          <div class="middle2 col-lg-7 col-md-6">
            <div class="search-header-w">
              <div class="icon-search hidden-lg hidden-md hidden-sm" :class="{'active' : hiddenSearch}" @click="openSideSearch" ><i class="fa fa-search"></i></div>
              <div id="sosearchpro" class="sosearchpro-wrapper so-search ">
                  <div id="search0" class="search input-group form-group"  >
                    <div class="select_category filter_type  icon-select hidden-sm hidden-xs">
                      <select class="no-border" name="category_id" v-model="choseCategory">
                        <option value="">모든 카테고리</option>
                        <option value="new">신규 이벤트</option>
                        <option value="recommended">추천 이벤트</option>
                        <option v-for="(list, index) in categoryList" :key="index" :value="list.commonCode">{{list.commonName}}</option>

                      </select>
                    </div>

                    <input class="autosearch-input form-control" type="text" value="" v-model="keyword" size="50" autocomplete="off" placeholder="키워드를 입력해주세요" name="search">
                    <span class="input-group-btn">
                      <button type="button" class="button-search btn btn-primary" name="submit_search" @click="skipNum = 0; toMainProductList(null)"><i class="fa fa-search"></i></button>
                    </span>



                  </div>
                  <input type="hidden" name="route" value="product/search" />
              </div>
            </div>
          </div>
          <!-- //end Search -->

          <div class="middle3 col-lg-3 col-md-3">
            <!--cart-->
<!--            <div class="shopping_cart">-->
<!--              <div id="cart" class="btn-shopping-cart">-->

<!--                <a data-loading-text="Loading... " class="btn-group top_cart dropdown-toggle" data-toggle="dropdown" aria-expanded="true">-->
<!--                  <div class="shopcart">-->
<!--                                    <span class="icon-c">-->
<!--                                    <i class="fa fa-shopping-bag"></i>-->
<!--                                  </span>-->
<!--                    <div class="shopcart-inner">-->
<!--                      <p class="text-shopping-cart">-->
<!--                        My cart-->
<!--                      </p>-->

<!--                      <span class="total-shopping-cart cart-total-full">-->
<!--                                            <span class="items_cart">02</span><span class="items_cart2"> item(s)</span><span class="items_carts"> - $162.00 </span>-->
<!--                                        </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <ul class="dropdown-menu pull-right shoppingcart-box" role="menu">-->
<!--                  <li>-->
<!--                    <table class="table table-striped">-->
<!--                      <tbody>-->
<!--                      <tr>-->
<!--                        <td class="text-center" style="width:70px">-->
<!--                          <a href="product.html">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/1.jpg" style="width:70px" alt="Yutculpa ullamcon" title="Yutculpa ullamco" class="preview">-->
<!--                          </a>-->
<!--                        </td>-->
<!--                        <td class="text-left"> <a class="cart_product_name" href="product.html">Yutculpa ullamco</a>-->
<!--                        </td>-->
<!--                        <td class="text-center">x1</td>-->
<!--                        <td class="text-center">$80.00</td>-->
<!--                        <td class="text-right">-->
<!--                          <a href="product.html" class="fa fa-edit"></a>-->
<!--                        </td>-->
<!--                        <td class="text-right">-->
<!--                          <a onclick="cart.remove('2');" class="fa fa-times fa-delete"></a>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <td class="text-center" style="width:70px">-->
<!--                          <a href="product.html">-->
<!--                            <img src="../../assets/image/catalog/demo/product/80/2.jpg" style="width:70px" alt="Xancetta bresao" title="Xancetta bresao" class="preview">-->
<!--                          </a>-->
<!--                        </td>-->
<!--                        <td class="text-left"> <a class="cart_product_name" href="product.html">Xancetta bresao</a>-->
<!--                        </td>-->
<!--                        <td class="text-center">x1</td>-->
<!--                        <td class="text-center">$60.00</td>-->
<!--                        <td class="text-right">-->
<!--                          <a href="product.html" class="fa fa-edit"></a>-->
<!--                        </td>-->
<!--                        <td class="text-right">-->
<!--                          <a onclick="cart.remove('1');" class="fa fa-times fa-delete"></a>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <div>-->
<!--                      <table class="table table-bordered">-->
<!--                        <tbody>-->
<!--                        <tr>-->
<!--                          <td class="text-left"><strong>Sub-Total</strong>-->
<!--                          </td>-->
<!--                          <td class="text-right">$140.00</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td class="text-left"><strong>Eco Tax (-2.00)</strong>-->
<!--                          </td>-->
<!--                          <td class="text-right">$2.00</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td class="text-left"><strong>VAT (20%)</strong>-->
<!--                          </td>-->
<!--                          <td class="text-right">$20.00</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td class="text-left"><strong>Total</strong>-->
<!--                          </td>-->
<!--                          <td class="text-right">$162.00</td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                      </table>-->
<!--                      <p class="text-right"> <a class="btn view-cart" href="cart.html"><i class="fa fa-shopping-cart"></i>View Cart</a>&nbsp;&nbsp;&nbsp; <a class="btn btn-mega checkout-cart" href="checkout.html"><i class="fa fa-share"></i>Checkout</a>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->

<!--            </div>-->
            <!--//cart-->

<!--            <ul class="wishlist-comp hidden-md hidden-sm hidden-xs">-->
<!--              <li class="compare hidden-xs"><a href="#" class="top-link-compare" title="Compare "><i class="fa fa-refresh"></i></a>-->
<!--              </li>-->
<!--              <li class="wishlist hidden-xs"><a href="#" id="wishlist-total" class="top-link-wishlist" title="Wish List (0) "><i class="fa fa-heart"></i></a>-->
<!--              </li>-->
<!--            </ul>-->
          </div>


        </div>

      </div>
    </div>
    <!-- //Header center -->





    <!-- Header Bottom -->
    <div class="header-bottom hidden-compact">
      <div class="container">
        <div class="row">

          <div class="bottom1 menu-vertical col-lg-2 col-md-3">
            <!-- Secondary menu -->
            <div class="responsive so-megamenu megamenu-style-dev">
              <div class="so-vertical-menu ">
                <nav class="navbar-default">

                  <div class="container-megamenu vertical">
                    <div id="menuHeading">
                      <div class="megamenuToogle-wrapper">
                        <div class="megamenuToogle-pattern">
                          <div class="container"  @click="bigMegaCategory">
                            <div>
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                            모든 카테고리
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="navbar-header">
                      <button type="button" id="show-verticalmenu" data-toggle="collapse" class="navbar-toggle" @click="showLeftMenu">
                        <i class="fa fa-bars"></i>
                        <span>  모든 카테고리    </span>
                      </button>
                    </div>
                    <div class="vertical-wrapper" :class="{'so-vertical-active': leftMenu}"  >
                      <span id="remove-verticalmenu" class="fa fa-times" @click="showLeftMenu"></span>
                      <div class="megamenu-pattern" >
                        <div class="container-mega">
                          <ul class="megamenu" id="bigViewMegaMenu">
                            <li class="item-vertical">
                                <p class="close-menu"></p>
                                <a href="#" class="clearfix"  @click="toMainProductList('')">
                                  <img src="../../assets/image/catalog/menu/icons/ico1.png" alt="icon">
                                  <span>전체</span>
                                </a>
                              </li>
                            <li class="item-vertical">
                              <p class="close-menu"></p>
                              <a href="#" class="clearfix"  @click="toMainProductList('new')">
<!--                                <img src="../../assets/image/catalog/menu/icons/ico2.png" alt="icon">-->
                                <span>신규 이벤트</span>
                              </a>
                            </li>
                            <li class="item-vertical">
                              <p class="close-menu"></p>
                              <a href="#" class="clearfix"  @click="toMainProductList('recommended')">
<!--                                <img src="../../assets/image/catalog/menu/icons/ico1.png" alt="icon">-->
                                <span>추천 이벤트</span>
                              </a>
                            </li>
                            <li class="item-vertical   " v-for="(list, index) in categoryList" :key="index">
                              <p class="close-menu"></p>
                              <a href="#" class="clearfix" @click="toMainProductList(list.commonCode)">

<!--                                <img src="../../assets/image/catalog/menu/icons/ico10.png" alt="icon">-->
                                <span>{{list.commonName}}</span>
<!--                                <b class="caret"></b>-->
                              </a>
<!--                              <div class="sub-menu" data-subwidth="60"  >-->
<!--                                <div class="content" >-->
<!--                                  <div class="row">-->
<!--                                    <div class="col-sm-12">-->
<!--                                      <div class="row">-->
<!--                                        <div class="col-md-4 static-menu">-->
<!--                                          <div class="menu">-->
<!--                                            <ul>-->
<!--                                              <li>-->
<!--                                                <a href="#"  class="main-menu">Apparel</a>-->
<!--                                                <ul>-->
<!--                                                  <li><a href="#" >Accessories for Tablet PC</a></li>-->
<!--                                                  <li><a href="#" >Accessories for i Pad</a></li>-->
<!--                                                  <li><a  href="#" >Accessories for iPhone</a></li>-->
<!--                                                  <li><a href="#" >Bags, Holiday Supplies</a></li>-->
<!--                                                  <li><a href="#" >Car Alarms and Security</a></li>-->
<!--                                                  <li><a href="#" >Car Audio &amp; Speakers</a></li>-->
<!--                                                </ul>-->
<!--                                              </li>-->
<!--                                              <li>-->
<!--                                                <a href="#"  class="main-menu">Cables &amp; Connectors</a>-->
<!--                                                <ul>-->
<!--                                                  <li><a href="#" >Cameras &amp; Photo</a></li>-->
<!--                                                  <li><a href="#" >Electronics</a></li>-->
<!--                                                  <li><a href="#" >Outdoor &amp; Traveling</a></li>-->
<!--                                                </ul>-->
<!--                                              </li>-->
<!--                                            </ul>-->
<!--                                          </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 static-menu">-->
<!--                                          <div class="menu">-->
<!--                                            <ul>-->
<!--                                              <li>-->
<!--                                                <a href="#"  class="main-menu">Camping &amp; Hiking</a>-->
<!--                                                <ul>-->
<!--                                                  <li><a href="#" >Earings</a></li>-->
<!--                                                  <li><a href="#" >Shaving &amp; Hair Removal</a></li>-->
<!--                                                  <li><a href="#" >Salon &amp; Spa Equipment</a></li>-->
<!--                                                </ul>-->
<!--                                              </li>-->
<!--                                              <li>-->
<!--                                                <a href="#" class="main-menu">Smartphone &amp; Tablets</a>-->
<!--                                                <ul>-->
<!--                                                  <li><a href="#" >Sports &amp; Outdoors</a></li>-->
<!--                                                  <li><a href="#" >Bath &amp; Body</a></li>-->
<!--                                                  <li><a href="#" >Gadgets &amp; Auto Parts</a></li>-->
<!--                                                </ul>-->
<!--                                              </li>-->
<!--                                            </ul>-->
<!--                                          </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-md-4 static-menu">-->
<!--                                          <div class="menu">-->
<!--                                            <ul>-->
<!--                                              <li>-->
<!--                                                <a href="#"  class="main-menu">Bags, Holiday Supplies</a>-->
<!--                                                <ul>-->
<!--                                                  <li><a href="#" onclick="window.location = '18_46';">Battereries &amp; Chargers</a></li>-->
<!--                                                  <li><a href="#" onclick="window.location = '24_64';">Bath &amp; Body</a></li>-->
<!--                                                  <li><a href="#" onclick="window.location = '18_45';">Headphones, Headsets</a></li>-->
<!--                                                  <li><a href="#" onclick="window.location = '18_30';">Home Audio</a></li>-->
<!--                                                </ul>-->
<!--                                              </li>-->
<!--                                            </ul>-->
<!--                                          </div>-->
<!--                                        </div>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
                            </li>
<!--                            <li class="item-vertical">-->
<!--                              <p class="close-menu"></p>-->
<!--                              <a href="#" class="clearfix">-->
<!--                                <img src="../../assets/image/catalog/menu/icons/ico1.png" alt="icon">-->
<!--                                <span>Fashion & Accessories</span>-->
<!--                              </a>-->
<!--                            </li>-->

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <!-- // end Secondary menu -->
          </div>

          <div class="main-menu col-lg-6 col-md-9">
            <div class="responsive so-megamenu megamenu-style-dev">
              <nav class="navbar-default">
                <div class=" container-megamenu  horizontal open ">
                  <div class="navbar-header">
                    <button type="button" id="show-megamenu" data-toggle="collapse" class="navbar-toggle" @click="showRightMenu">
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div>




<!--                  모바일 화면일 때 = 누르면 나오는 옆 메뉴 -->

                  <div class="megamenu-wrapper " :class="{'so-megamenu-active' : rightMenu}">
                    <span id="remove-megamenu" class="fa fa-times" @click="showRightMenu"></span>
                    <div class="megamenu-pattern">
                      <div class="container-mega">
                        <ul class="megamenu" data-transition="slide" data-animationtime="250">


<!--                          <li class="">-->
<!--                            <p class="close-menu"></p>-->
<!--                            <router-link to="/" class="clearfix" @click="hideMenus">-->
<!--                              <strong>홈</strong>-->
<!--                            </router-link>-->


<!--                          </li>-->
                          <li class="" v-if="$store.getters['loginStore/companyCode'] == null || $store.getters['loginStore/companyCode'].companyCode == null">
                            <p class="close-menu"></p>
                            <button type="button" class="btn clearfix" style="background: transparent; color: white;" @click="toLogin">
                              <strong> 로그인</strong>
                            </button>

                          </li>
                          <li class=""  v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null">
                            <p class="close-menu"></p>
                            <button type="button" class="btn clearfix" style="background: transparent; color: white;" @click="setUser">
                              <strong>계정 관리</strong>
                            </button>

                          </li>
                          <li class=""  v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null">
                            <p class="close-menu"></p>
                            <button type="button" class="btn clearfix" style="background: transparent; color: white;" @click="setProduct">
                              <strong>상품 관리</strong>
                            </button>

                          </li>

                          <li class=""  v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null">
                            <p class="close-menu"></p>
                            <button type="button" class="btn clearfix"  style="background: transparent; color: white;" @click="logout">
                              <strong>로그아웃</strong>
                            </button>

                          </li>

                        </ul>

                      </div>
                    </div>
                  </div>




                </div>
              </nav>
            </div>
          </div>



          <div class="bottom2 col-lg-4 hidden-md col-sm-6 col-xs-8" style=" float: right; display: block !important;">
            <div class="signin-w font-title hidden-sm hidden-xs">
              <ul class="signin-link blank">
                <li class="log login" v-if="$store.getters['loginStore/companyCode'] == null || $store.getters['loginStore/companyCode'].companyCode == null"> <i class="fa fa-lock"></i> <router-link class="link-lg" style="text-decoration: underline;" to="/login"><b>로그인</b> </router-link>
                  또는 <router-link to="/signIn" style="text-decoration: underline;"><b>회원가입</b></router-link> 하기
                </li>
                <li class="log login" v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null">  <button type="button" class="btn btn-default"  style="border:none !important;" @click="setUser"><i class="fa fa-user"></i> 계정 관리</button></li>
                <li class="log login" v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null">  <button type="button" class="btn btn-default"  style="border:none !important;" @click="setProduct"><i class="fa fa-folder"></i> 상품 목록</button></li>
                <li class="log login" v-if="$store.getters['loginStore/companyCode'] != null && $store.getters['loginStore/companyCode'].companyCode != null"> <button type="button" class="link-lg btn btn-default" style="border:none !important;" @click="logout"><i class="fa fa-lock"></i> 로그아웃</button></li>
              </ul>
            </div>
            <!--              <div class="telephone hidden-xs hidden-sm hidden-md">-->
            <!--                <ul class="blank"> <li><a href="#"><i class="fa fa-truck"></i>track your order</a></li> <li><a href="#"><i class="fa fa-phone-square"></i>Hotline (+123)4 567 890</a></li> </ul>-->
            <!--              </div>-->


          </div>


        </div>
      </div>

    </div>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventbus from "@/store/module/eventbus";

export default {
  name: 'HeaderView',
  computed : {
    // ...mapGetters('loginStore',[
    //   'companyCode',
    //   // 'userEmail',
    //   // 'insertDt'
    // ]),
    ...mapGetters('productsStore',[
      'productsList'
    ]),
    // ...mapGetters('paginateStore',[
    //   'pageNum',
    //   'skipNum',
    //   'totalCount',
    // ]),
    accountDisplayData(){
      return {
        display : this.classObject.accountDisplay
      }
    },
    changeCateg(){
      console.log(this.$store.getters["productsStore/commonCode"])

      if(this.$store.getters["productsStore/commonCode"] == null){
        return "";
      } else {
        return this.$store.getters["productsStore/commonCode"].commonCode

      }

    },
    // setCodess(){
    //   return this.$store.getters['loginStore/companyCode'].companyCode == null
    // }

  },
  data(){
    return {
      keyword : "",
      choseCategory : "",

      categoryList : null,

      skipNum : 0,
      loginTrueFalse : false,

      leftMenu : false,
      rightMenu : false,
      hiddenSearch : false,
      accountOpen : false,
      classObject : {
        display : 'none',
        accountDisplay : 'none',
      },

      // accountObject : {
      //   display : 'none',
      // }
    }
  },
  watch : {
    changeCateg (val){
      this.choseCategory = val;
    },
    // setCodess(val){
    //   console.log("dsfsf")
    //  this.loginTrueFalse = val;
    // }
  },
  methods : {
    toMainLogo(){
      this.$store.dispatch('productsStore/settingProductsListInMain', {products : {keyword : '', commonCode : '', commonName : '모든 카테고리', productsList : []}, totalCount : 0});
      this.$router.push({name:'MainPage'})

    },
    bigMegaCategory(){
      if(document.getElementById("bigViewMegaMenu").style.display == 'block'){
        document.getElementById("bigViewMegaMenu").style.display = "none";

      } else {
        document.getElementById("bigViewMegaMenu").style.display = "block";

      }
    },
    openAccount(){
      this.accountOpen = !this.accountOpen

      console.log("변경된" , this.accountOpen)

      if(this.accountOpen){
        document.getElementById("account-dropdown-id").style.display = "block";

      } else {

        document.getElementById("account-dropdown-id").style.display = "none";
      }



      // if(this.accountOpen){
      //   this.classObject.accountDisplay = 'block'
      //   // document.getElementById("account-dropdown-id").style.display = 'block !important';
      // } else {
      //   this.classObject.accountDisplay = 'none'
      //   // this.accountObject = { display : 'none'}
      //   // document.getElementById("account-dropdown-id").style.display = 'none !important';
      //
      // }

    },
    hideMenus(){

      this.leftMenu = false;
      this.rightMenu = false;
      this.hiddenSearch = false;
      this.accountOpen = false;

      document.getElementById("account-dropdown-id").style.display = "none";

    },
    showLeftMenu(){
      document.getElementById("bigViewMegaMenu").style.display = "block";
      this.leftMenu = !this.leftMenu

    },
    showRightMenu(){
      this.rightMenu = !this.rightMenu
    },
    openSideSearch(){
      console.log("클릭")
      this.hiddenSearch = !this.hiddenSearch;

      if(this.hiddenSearch){

        document.getElementById("search0").style.display = 'block';

      } else {
        document.getElementById("search0").style.display = 'none';

      }

    },
    toMainProductList(value){

      var name = "";

      if(value != null){
        this.choseCategory = value;
        this.skipNum = 0;
      }


      this.$store.dispatch("paginateStore/setPaginating", { pageNum : 15, skipNum : this.skipNum, totalCount : 0 })



      console.log("키워드로 검색", value)
      console.log("키워드로 검색", this.choseCategory)
      console.log(this.$store.getters["paginateStore/pageNum"])


      if(this.choseCategory != "" && this.choseCategory != "new" && this.choseCategory != "recommended"){

        for(var i = 0; i < this.categoryList.length; i++){
          if(this.categoryList[i].commonCode == this.choseCategory){
            name = this.categoryList[i].commonName;
          }
        }

      } else if(this.choseCategory == ''){
        name = "모든 카테고리"
      } else if(this.choseCategory == 'new'){
        name = "신규 이벤트"
      } else if(this.choseCategory == 'recommended'){
        name = "추천 이벤트"
      }

      var param = {
        keyword : this.keyword,
        commonCode : this.choseCategory,
        pageNum : 15,
        skipNum : this.skipNum,
      }

      console.log(param)
      console.log(name)

      this.api.products(param)
      .then((res)=>{
        console.log(res)

        var data = {keyword : this.keyword, commonCode : this.choseCategory, commonName : name , productsList : res.product};

        // this.$store.state.paginate.totalCount = res.totalCount;

        this.$store.dispatch("productsStore/settingProductsListInMain", {products : data, totalCount : res.totalCount})
        this.$store.dispatch("paginateStore/setTotalCount", { totalCount : res.totalCount})



        console.log(this.$route.name)

        if(this.$route.name != 'MainProductsPage'){
          this.$router.push({name: "MainProductsPage"})
      // this.$router.push({name: "MainProductsPage", params : {keyword : this.keyword, commonCode : this.choseCategory}})
        }

        this.hideMenus()



      })



    },
    toMainCategoryList(){
      console.log("전체 카테고리 조회")

      var param = {
        keyword : this.keyword
      }

      this.api.category(param)
      .then((res)=>{
        console.log(res);

        this.categoryList = res.category;
        this.$store.dispatch('productsStore/setCategoryTotal', {category : res.category})

      })

    },
    setProduct(){
      this.hideMenus();
      console.log("상품 관리");

      this.$router.push({name: "ProductPage"})

    },
    setPlan(){
      console.log("플랜 관리");
    },
    setUser(){
      this.hideMenus();

      console.log("계정 관리");

      this.$router.push({ name: "ProfilePage" })
    },
    toLogin(){
      this.hideMenus();

      console.log("로그인 관리");

      this.$router.push({ name: "LoginPage" })
    },
    logout(){
      this.hideMenus()

      if(confirm("로그아웃 하시겠습니까?")){
        // var logout = { companyCode : null, userEmail : null,  insertDt : null, deleteYn : null };

        this.api.signOut()
          .then(() =>{
            //
            // this.$store.state.loginStore.companyCode = null;
            // this.$store.state.loginStore.userEmail = null;
            // this.$store.state.loginStore.insertDt = null;
            // this.$store.state.loginStore.deleteYn = null;

            this.$store.dispatch("loginStore/settingLogin", { companyCode : null, userEmail : null,  insertDt : null, deleteYn : null });
            this.$router.push({name: "LoginPage"})

          })
      }




    },
  },
  created() {
    console.log('HeaderView.created');

    eventbus.$on("changingPage",(res)=>{
      console.log(res)

      this.skipNum = res;
      this.toMainProductList();

    })



  },
  mounted() {
    console.log('HeaderView.mounted');

    //이거 주석 함

    this.toMainCategoryList();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
#bigViewMegaMenu {
  display: block;
}
</style>
