import Vue from 'vue';
import VueRouter from 'vue-router';
// import { requireAuth } from './requireAuth';
import LoginPage from '../company/LoginPage.vue';
import SignInPage from '../company/SignInPage.vue';
import ProfilePage from '../company/ProfilePage.vue';
import MainPage from '../company/MainPage.vue';
import PlanPage from '../plan/PlanPage.vue';
import MainProductsPage from '../company/MainProductsPage.vue';
import MainProductsDetailPage from '../company/MainProductsDetailPage.vue';
import ProductPage from '../company/ProductPage.vue';
import ProductAddPage from '../company/ProductAddPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/signIn',
    name: 'SignInPage',
    component: SignInPage,
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/plan',
    name: 'PlanPage',
    component: PlanPage,
  },
  {
    path: '/products',
    name: 'MainProductsPage',
    component: MainProductsPage,
  },
  {
    path: '/products/detail',
    name: 'MainProductsDetailPage',
    component: MainProductsDetailPage,
  },
  {
    path: '/productList',
    name: 'ProductPage',
    component: ProductPage,
  },
  {
    path: '/productAdd',
    name: 'ProductAddPage',
    component: ProductAddPage,
    promp : true,
  },
];
const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
