<template>
  <footer class="footer-container typefooter-2">
    <!-- Footer Top Container -->
    <section class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-8 col-sm-3 col-xs-12">
            <div class="logo-footer">
                <img src="../../assets/image/logo/white_no.png" alt="image" style="">
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 hidden-sm hidden-xs" style="margin-top: 50px; display: block !important; float:right;">
            <ul class="socials">
<!--              <li class="facebook"><a class="_blank" href="https://www.facebook.com/MagenTech" target="_blank">소개</a>-->
<!--              </li>-->
              <li class="twitter"><router-link to="/plan">플랜</router-link>
              </li>
            </ul>
          </div>
<!--          <div class="col-lg-7 col-md-6 col-sm-9 col-xs-12 hidden-xs" style="float: right;">-->
<!--            <ul class="footer-links" style="margin-top: 10px;">-->
<!--              <li><a href="index.html?route=information/information&information_id=4">회사 소개</a>-->
<!--              </li>-->
<!--              <li><router-link to="/plan">플랜</router-link>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </div>-->

        </div>
      </div>
    </section>
    <!-- /Footer Top Container -->

    <section class="footer-middle ">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-style">
            <div class="infos-footer box-footer">
              <div class="module">
                <h3 class="modtitle">ModuPang</h3>
                <ul>
                  <li class="adres">서울특별시 마포구 백범로31길 7, SK리더스뷰 2동 5층 512호</li>
                  <li class="phone">02-2038-9145</li>
<!--                  <li class="mail">-->
<!--                    <a href="mailto:contact@opencartworks.com">support@modupang.com</a>-->
<!--                  </li>-->
                  <li class="time">운영 시간: 9:00AM - 6:00PM</li>
                </ul>
              </div>
            </div>


          </div>

          <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12 col-style">
            <div class="box-account box-footer">
              <div class="module clearfix">
                <h3 class="modtitle">연계 서비스 </h3>
                <div class="modcontent">
                  <ul class="menu">
                    <li><a href="http://www.zapier.com" target="_blank">Zapier</a></li>
                    <li><a href="http://www.modutalk.com" target="_blank">모두톡</a></li>

                  </ul>
                </div>
              </div>
            </div>


          </div>





        </div>
<!--        <div class="newsletter-footer1">-->
<!--          <div class="newsletter" style="width:100%; background-color: #fff; ">-->

<!--            <div class="title-block">-->
<!--              <div class="page-heading font-title">-->
<!--                Signup for Newsletter-->
<!--              </div>-->
<!--              <div class="promotext">We’ll never share your email address with a third-party. </div>-->
<!--            </div>-->

<!--            <div class="block_content">-->
<!--              <form method="post" id="signup" name="signup" class="form-group form-inline signup send-mail">-->
<!--                <div class="form-group">-->
<!--                  <div class="input-box">-->
<!--                    <input type="email" placeholder="Your email address..." value="" class="form-control" id="txtemail" name="txtemail" size="55">-->
<!--                  </div>-->
<!--                  <div class="subcribe">-->
<!--                    <button class="btn btn-primary btn-default font-title" type="submit" onclick="return subscribe_newsletter();" name="submit">-->
<!--                      Subscribe-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->


<!--            </div>-->
<!--            &lt;!&ndash;/.modcontent&ndash;&gt;-->

<!--          </div>-->

<!--        </div>-->
      </div>
    </section>

    <!-- Footer Bottom Container -->
    <section class="footer-bottom ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12 copyright-w">
            <div class="copyright">주식회사 창업가게 |Tel : 02-2038-9145| 주소 : 서울특별시 마포구 백범로31길 7, SK리더스뷰 2동 5층 512호 © All Rights Reserved
            </div>
          </div>
<!--          <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12 payment-w">-->
<!--            <img src="../../assets/image/catalog/demo/payment/payment.png" alt="imgpayment">-->
<!--          </div>-->
        </div>
      </div>
    </section>
    <!-- /Footer Bottom Container -->


    <!--Back To Top-->
    <div class="back-to-top" @click="toTop"><i class="fa fa-angle-up"></i></div>
  </footer>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'MainPage',

  methods : {
    toTop(){
      $('html, body').animate({scrollTop:0},400);
      return false;
    }

  },
  created() {
    console.log('HeaderView.created');
  },
  mounted() {
    console.log('HeaderView.mounted');
  },
  beforeDestroy() {
    console.log('HeaderView.beforeDestroy');
  },
};
</script>
