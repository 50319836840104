export default class ResultCode {
  static Success = 0; // 성공
  static Failed = -1; // 실패
  static Unauthorized = 401; // Unauthorized
  static ApiNoApiKey = 501; // X-API-KEY가 없습니다.
  static ApiNoAuth = 502; // 인증이 유효하지 않습니다.
  static ApiNotValid = 503; // API 통신 중 오류가 발생했습니다.
  static BadParams = 10100; // 파라미터가 유효하지 않습니다.
  static DbFailed = 99999; // DB 실패
  static NotExistSession = 11001; // 세션이 없습니다.
  static ExistSession = 11002; // 세션이 있습니다.
  static AlreadyExistEmail = 12001; // 이미 사용중인 이메일입니다.
  static NotExistEmail = 12002; // 존재하지 않는 이메일입니다.
  static get(code /*: number */) {
    switch (code) {
      case 0:
        return '성공';
      case -1:
        return '실패';
      case 401:
        return 'Unauthorized';
      case 501:
        return 'X-API-KEY가 없습니다.';
      case 502:
        return '인증이 유효하지 않습니다.';
      case 503:
        return 'API 통신 중 오류가 발생했습니다.';
      case 10100:
        return '파라미터가 유효하지 않습니다.';
      case 99999:
        return 'DB 실패';
      case 11001:
        return '세션이 없습니다.';
      case 11002:
        return '세션이 있습니다.';
      case 12001:
        return '이미 사용중인 이메일입니다.';
      case 12002:
        return '존재하지 않는 이메일입니다.';
    }
    return 'Uknown';
  }
}
