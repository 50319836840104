import { paginateStoreActions } from './paginateStoreActions';
export const paginateStore = {
  namespaced: true,
  state: {
    pageNum: '', //  
    skipNum: '', // 
    totalCount: '', // 
  },
  getters: {
    pageNum: state => {
      if (state.pageNum === undefined || state.pageNum === null || state.pageNum === '') {
        const jsonStr = localStorage.getItem('pageNum');
        if (jsonStr === undefined || jsonStr === null) {
          state.pageNum = null;
          return state.pageNum;
        } else {
          state.pageNum = JSON.parse(jsonStr);
        }
      }
      return state.pageNum;
    },
    skipNum: state => {
      if (state.skipNum === undefined || state.skipNum === null || state.skipNum === '') {
        const jsonStr = localStorage.getItem('skipNum');
        if (jsonStr === undefined || jsonStr === null) {
          state.skipNum = null;
          return state.skipNum;
        } else {
          state.skipNum = JSON.parse(jsonStr);
        }
      }
      return state.skipNum;
    },
    totalCount: state => {
      if (state.totalCount === undefined || state.totalCount === null || state.totalCount === '') {
        const jsonStr = localStorage.getItem('totalCount');
        if (jsonStr === undefined || jsonStr === null) {
          state.totalCount = null;
          return state.totalCount;
        } else {
          state.totalCount = JSON.parse(jsonStr);
        }
      }
      return state.totalCount;
    },
  },
  mutations: {
    pageNum: (state, pageNum) => {
      if (pageNum === undefined || pageNum === null) {
        localStorage.removeItem('pageNum');
      } else {
        localStorage.setItem('pageNum', JSON.stringify(pageNum));
      }
      state.pageNum = pageNum;
    },
    skipNum: (state, skipNum) => {
      if (skipNum === undefined || skipNum === null) {
        localStorage.removeItem('skipNum');
      } else {
        localStorage.setItem('skipNum', JSON.stringify(skipNum));
      }
      state.skipNum = skipNum;
    },
    totalCount: (state, totalCount) => {
      if (totalCount === undefined || totalCount === null) {
        localStorage.removeItem('totalCount');
      } else {
        localStorage.setItem('totalCount', JSON.stringify(totalCount));
      }
      state.totalCount = totalCount;
    },
  },
  actions: paginateStoreActions,
};
