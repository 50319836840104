<template>

    <!-- Main Container  -->
    <div class="main-container container">
        <ul class="breadcrumb">
            <li><router-link to="/"><i class="fa fa-home"></i></router-link></li>
            <li><button type="button" class="btn btn-default" style="border:none !important;">상품 관리</button></li>
        </ul>

        <div class="row">
            <!--Middle Part Start-->
            <div id="content" class="col-sm-12">
                <button type="button" style="float: right;" class="btn btn-primary" title="" data-toggle="tooltip" data-original-title="setting" @click="addProduct"><i class="fa fa-plus"></i></button>
                <h2 class="title">상품 목록</h2>
                <div class="table-responsive" v-if="list != null && list.length > 0" style="min-height: 700px;">
                    <table class="table table-bordered table-hover">
                        <colgroup>
                            <col width="5%" />
                            <col width="20%" />
                            <col width="*" />
                            <col width="20%"/>
                            <col width="10%"  />
                            <!--              <col width="10%" />-->
                            <col width="5%"  />
                        </colgroup>
                        <thead>
                        <tr>
                            <td  class="text-center">#</td>
                            <!--              <td  class="text-center">이미지(* 최대 2장)</td>-->
                            <td  class="text-center">상품명</td>
                            <td   class="text-center">생성일</td>
                            <td  class="text-center">판매가 (원가)</td>
                            <td   class="text-center">공개</td>
                            <td></td>
                            <!--              <td  class="text-center">총</td>-->
                            <!--              <td class="text-right"></td>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(each, index) in list" :key="index">
                            <td class="text-center">
                                {{ totalCount - (skipNum * pageNum) - index }}
                            </td>
                            <!--              <td class="text-center">-->
                            <!--                <div v-if="each.imgList != ''">-->
                            <!--                  {{splitImage(each.imgList)}}-->

                            <!--                      <div class="" v-for="(one, index) in imgList" :key="index" style="float:left;">-->
                            <!--                        <img width="85" class="img-thumbnail" :src="getImageByPath(one)" style="width: 80px;height: 80px;">-->
                            <!--  &lt;!&ndash;                      {{one}}&ndash;&gt;-->
                            <!--                      </div>-->

                            <!--                </div>-->
                            <!--                <div v-if="each.imgList == ''">-->
                            <!--                  <div class=""  style="float:left;">-->
                            <!--                    <img width="85" class="img-thumbnail" src="https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/320.jpg" style="width: 80px;height: 80px;">-->
                            <!--                    &lt;!&ndash;                      {{one}}&ndash;&gt;-->
                            <!--                  </div>-->

                            <!--                </div>-->
                            <!--              </td>-->
                            <td class="text-center">{{each.productsName}}
                            </td>
                            <td class="text-center">{{each.insertDt}}</td>
                            <td class="text-center">{{ numberComma(each.sellPrice)}} ({{ numberComma(each.defaultPrice) }})</td>
                            <td class="text-center">{{ publicOrNot(each.publicYn)}}</td>
                            <!--              <td class="text-center">{{each.sellPrice}}</td>-->
                            <!--              <td class="text-right">수정</td>-->
                            <td class="text-center">


                                <div class="dropdown">
                                    <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" @click="dropdownBtn(index)">
                                        <i class="fa fa-gear"></i>
                                    </button>
                                    <ul class="dropdown-menu" :class="{'show' : each.modal}" aria-labelledby="dropdownMenuButton1" style="right: 0;left: auto;">
                                        <li v-if="each.publicYn == 'N'"><button type="button" class="btn dropdown-item" style="background: transparent;" @click="publicProduct(each.productsCode, 'Y')">공개</button></li>
                                        <li v-if="each.publicYn == 'Y'"><button type="button" class="btn dropdown-item" style="background: transparent;" @click="publicProduct(each.productsCode, 'N')">비공개</button></li>
                                        <li><button type="button" class="btn dropdown-item" style="background: transparent;" @click="editProduct(each.productsCode)">수정</button></li>
                                        <li><button type="button" class="btn dropdown-item" style="background: transparent;" @click="removeProduct(each.productsCode)">삭제</button></li>
                                    </ul>
                                </div>


                            </td>
                        </tr>

                        </tbody>
                    </table>
                    <div style=" text-align: right; margin-bottom: 10px;">
                        <!--            <paginate  @activePage="setNewPage" v-bind:skipNum="skipNum"/>-->


                        <div >
                            <button type="button" class="btn btn-action " v-if="skipNum !== 0 && skipNum > 0" @click="getPreviousPage">이전</button>
                            <span v-for="num in Math.ceil(totalCount / pageNum)" :key="num">

              <button class="btn" v-if="num != skipNum + 1 && num < skipNum + 4 && num > skipNum - 4" style="color: #914100" @click="skipToPage(num)">{{ num }}</button>
              <button type="button" class="btn" v-if="num == skipNum + 1 " style="color: #ffa600" @click="skipToPage(num)">{{ num }}</button>
                </span>
                            <!--{{Math.floor(this.totalCount/this.pageNum)}}-->
                            <button type="button" class="btn btn-action " v-if=" (pageNum * skipNum + pageNum) < totalCount" @click="getNextPage">다음</button>

                        </div>






                    </div>
                </div>
                <div class="table-responsive"  v-if="list == null || list.length === 0" style="min-height: 700px;">
                    등록된 상품이 없습니다.
                </div>

            </div>
            <!--Middle Part End-->

        </div>
    </div>
    <!-- //Main Container -->


    <!--  <div>-->
    <!--    ProductPage-->
    <!--    <div>{{getCompanyData}}</div>-->
    <!--    <button type="button" @click="toMain">메인으로</button>-->
    <!--    <button type="button" @click="addProduct">상품 추가</button>-->


    <!--   <div>{{list}}</div>-->


    <!--  </div>-->
</template>

<script>
import { mapGetters } from "vuex";

// import Paginate from "@/views/common/Paginate";

export default {
    name: 'ProductPage',
    components : {
        // Paginate
    },
    computed : {
        ...mapGetters([
            'getCompanyData'
        ])

    },
    data(){
        return{
            list : null,
            listLength : 1,
            imgList : null,

            pageNum : 15,
            skipNum : 0,
            totalCount : 0,
        }
    },
    methods : {

        getNextPage() {
            var skip = this.$store.getters['paginateStore/skipNum'].skipNum + 1;
            this.skipNum = skip;

            this.$store.dispatch("paginateStore/setPaginating", {pageNum : this.pageNum, skipNum : skip, totalCount: this.totalCount})

            // this.$store.state.paginate.skipNum = this.skipNum;
            // this.$emit("activePage")
            this.productsLists();

            // this.getWorkflowList();

        },
        skipToPage(num) {
            var skip = num - 1;
            this.skipNum = skip;


            console.log("ㅇㄹㅇㄴㄹㅇ", this.skipping)

            this.$store.dispatch("paginateStore/setPaginating", {pageNum : this.pageNum, skipNum : skip, totalCount: this.totalCount})

            this.productsLists();
            // this.$emit("activePage")
            // this.getWorkflowList();

        },
        getPreviousPage() {
            var skip = this.$store.getters['paginateStore/skipNum'].skipNum - 1;
            this.skipNum = skip;
            this.$store.dispatch("paginateStore/setPaginating", {pageNum : this.pageNum, skipNum : skip, totalCount: this.totalCount})
            this.productsLists();
            // this.$emit("activePage")
            // this.getWorkflowList();
        },



        numberComma(value){

            if(value >= 0){
                return value.toLocaleString("ko-KR");

            } else {
                return "0";
            }
        },
        publicOrNot(value){
            if(value == 'N'){
                return "비공개"

            } else if(value == 'Y'){
                return "공개"
            }
        },
        getImageByPath(one){
            return "http://pang.frients.ai/modupang/" + one;
        },
        splitImage(value){

            this.imgList = null;

            if(value != null){
                var text = '';
                text = value.split(",")
                // console.log(text)
                // console.log(text.length)

                this.imgList = text;
            }

            // return this.imgList;
        },
        publicProduct(code, status){

            console.log(code, status)



            var param = {
                productCode : code,
                status : status
            }

            this.api.productSetPublic(param)
                .then(()=>{
                    console.log("수정 됨 ");
                    this.productsLists();
                })


        },
        removeProduct(value){
            if(confirm("상품을 삭제하시겠습니까?")){
                var param = {
                    productCode : value

                }


                this.api.productDelete(param)
                    .then(()=>{
                        alert("삭제되었습니다.")
                        this.productsLists();
                    })

            }

        },
        addProduct(){
            console.log("상품 추가 페이지로 이동")

            var param = {
                companyCode : this.$store.getters['loginStore/companyCode'].companyCode
            }

            this.api.getUserTask(param)
                .then((res)=>{
                    console.log("결과:",res)


                    if(res.task.endDt != null && res.task.endDt != ""){
                        var current = new Date();
                        var nowDate = new Date(current.getFullYear(), current.getMonth(), current.getDate())

                        var daysplit = res.task.endDt.split('-');

                        var endDt = new Date(daysplit[0], daysplit[1]-1, daysplit[2].substring(0,2));

                        console.log(endDt > nowDate)
                        console.log(endDt != nowDate)
                        console.log(endDt.getTime() )
                        console.log(nowDate.getTime() )

                        if(endDt < nowDate ){
                            alert("기간만료했습니다.")
                            return;
                        }

                    }

                    if(res.task.limitCount !== -1 && res.task.totalCount < res.task.limitCount){
                        this.$router.push({name: "ProductAddPage"})

                    }
                    else if(res.task.limitCount === -1){
                        this.$router.push({name: "ProductAddPage"})

                    }
                    else if(res.task.limitCount !== -1 && res.task.productsCount >= res.task.limitCount){
                        alert("등록가능한 갯수를 초과했습니다.")
                        return;
                    }
                })

        },
        editProduct(value){
            console.log("상품 추가 페이지로 이동")
            this.$router.push({name: "ProductAddPage", params : {productsCode : value}})
        },
        dropdownBtn(index){

            // for(var i = 0; i < this.list.length; i++){
            //
            //   // if(i != index){
            //     this.list[i].modal = false;
            //   // }
            //
            // }
            this.list[index].modal = !this.list[index].modal;

        },
        setNewPage(){
            // console.log("페이지 클릭", this.$store.getters.getPaginate.skipNum)


            this.skipNum = this.$store.getters['paginateStore/skipNum'].skipNum;
            console.log("슽ㅅ킵 넘:", this.skipNum)

            this.productsLists();
        },
        productsLists(){
            var param = {
                companyCode : this.$store.getters['loginStore/companyCode'].companyCode,
                pageNum : this.pageNum,
                skipNum : this.skipNum
            }

            console.log("파람:",param)


            this.api.productList(param)
                .then((res)=>{

                    if (this.utils.preApiHandle(res)) {
                        console.log("util : ", res);
                    }

                    this.totalCount = res.totalCount;

                    var supList = [];


                    if(res.product != null){
                        // console.log(res.product.length)
                        for(var i = 0; i < res.product.length; i++){
                            // console.log("dfdfd")

                            var ppp = {
                                companyCode : res.product[i].companyCode,
                                productsCode : res.product[i].productsCode,
                                productsName : res.product[i].productsName,
                                defaultPrice : res.product[i].defaultPrice,
                                publicYn : res.product[i].publicYn,
                                insertDt : res.product[i].insertDt,
                                discountPrice : res.product[i].discountPrice,
                                discountPercent : res.product[i].discountPercent,
                                sellPrice : res.product[i].sellPrice,
                                stockAmount : res.product[i].stockAmount,
                                imgList : res.product[i].imgList,
                                deleteYn : res.product[i].deleteYn,
                                modal : false
                            }

                            supList.push(ppp);
                        }
                    }
                    this.list = supList;
                    this.listLength = this.list.length;

                    this.paging()
                })
        },
        paging(){
            //setPaginating({commit}, {pageNum, skipNum, totalCount}

            this.$store.dispatch("paginateStore/setPaginating", {pageNum : this.pageNum, skipNum : this.skipNum, totalCount: this.totalCount})
        }
    },
    created() {
        console.log('ProductPage.created');
        this.productsLists();
        this.$store.dispatch("paginateStore/setSkipNumber", {skipNum : 0})
        // this.$store.state["paginateStore/skipNum"] = 0;
        // this.$store.dispatch("setPaginating", {pageNum : this.pageNum, skipNum : 0, totalCount: 0})


    },
    mounted() {
        console.log('ProductPage.mounted');
    },
    beforeDestroy() {
        console.log('ProductPage.beforeDestroy');
    },
};
</script>
