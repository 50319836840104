<template>
  <div class="main-container">
    <!--<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">-->



    <div id="content" >


      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0"   :class="{'liActive': topSlideNum == 0}" @click="topSlideNumCheck(0)"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"  :class="{'liActive': topSlideNum == 1}" @click="topSlideNumCheck(1)"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"  :class="{'liActive': topSlideNum == 2}" @click="topSlideNumCheck(2)"></li>
        </ol>
        <div class="carousel-inner" style=" text-align: center;">
          <div class="carousel-item " :class="{'active': topSlideNum == 0}">
            <img class="d-block w-100" src="../assets/image/catalog/slideshow/slide01_01.jpg" alt="First slide" >
          </div>
          <div class="carousel-item" :class="{'active': topSlideNum == 1}">
            <img class="d-block w-100" src="../assets/image/catalog/slideshow/slide02_01.jpg" alt="Second slide"  >
          </div>
          <div class="carousel-item" :class="{'active': topSlideNum == 2}">
            <img class="d-block w-100" src="../assets/image/catalog/slideshow/slide03_01.jpg" alt="Third slide"  >
          </div>
        </div>
      </div>



      <div class="container">
        <div class="block-policy2">
          <ul>
            <li class="item-1">
              <div class="item-inner">
                <div class="icon icon1"></div>
                <div class="content"> <a href="#">무료 쿠폰</a>
                  <p>언제든지 등록</p>
                </div>
              </div>
            </li>
            <li class="item-2">
              <div class="item-inner">
                <div class="icon icon2"></div>
                <div class="content"> <a href="#">온라인 지원</a>
                  <p>24시간 상시 대기</p>
                </div>
              </div>
            </li>
            <li class="item-3">
              <div class="item-inner">
                <div class="icon icon3"></div>
                <div class="content"> <a href="#">다양한 컨텐츠 제공</a>
                  <p>365일</p>
                </div>
              </div>
            </li>
            <li class="item-4">
              <div class="item-inner">
                <div class="icon icon4"></div>
                <div class="content"> <a href="#">안전한 결제</a>
                  <p>안전한 결제 수단</p>
                </div>
              </div>
            </li>
            <li class="item-5">
              <div class="item-inner">
                <div class="icon icon5"></div>
                <div class="content"> <a href="#">추가 할인</a>
                  <p>주말 할인</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!--              이번주 제일 많이 클릭한 -->
        <main-best-seller-page />
<!--        <main-new-page />-->

        <!--      광고-->

        <main-most-page />



      </div>

    </div>
  </div>
</template>


<script>
// import MainNewPage from "@/views/main/MainNewPage";
export default {
  // components: { MainNewPage }
  components: {  }
};
</script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js" integrity="sha512-9CWGXFSJ+/X0LWzSRCZFsOPhSfm6jbnL+Mpqo0o8Ke2SYr8rCTqb4/wGm+9n13HtDE1NQpAEOrMecDZw4FXQGg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>


<script>
// import $ from 'jquery';

import { mapGetters } from "vuex";
// import MainCategoryPage from "@/views/main/MainCategoryPage";
import MainBestSellerPage from "@/company/main/MainBestSellerPage";
import MainNewPage from "@/company/main/MainNewPage";
import MainMostPage from "@/company/main/MainMostPage";

export default {
  name: 'MainPage',
  components: {
    // 'MainCategoryPage':MainCategoryPage,
    'MainBestSellerPage':MainBestSellerPage,
    'MainNewPage':MainNewPage,
    'MainMostPage':MainMostPage
  },
  data(){
    return{
      slideIndex : 0,
      categoryVisible : false,
      bestSellingSelect : false,
      bestSellingCateg : '-- 선택 --',
      topSlideNum : 0,
      thisWeekFamous : 0,
      shopWithCategory : 6,


      // topScrollImgs : {
      //   width: '2110px',
      //   marginRight: '0px'
      // }
    }
  },
  computed : {
    ...mapGetters([
      'getCategories'
    ]),
  },

  methods : {
    clickBestSellingSelectBox(){
      this.bestSellingSelect = !this.bestSellingSelect
    },

    bestSellingCategory(value, text){
      this.bestSellingCateg = text
      console.log(value)

      this.clickBestSellingSelectBox();
    },
    topSlideNumCheck(num){

      this.topSlideNum = num;

    },
    sendCategorySelect(value){


      console.log(":dsfsds")

      var param = {
        keyword : '',
        commonCode : value,
        pageNum : '15',
        skipNum : '0',

      }

      this.api.products(param)
        .then((res)=> {
          console.log(res)

          var data = { keyword: this.keyword, commonCode: this.choseCategory, productsList: res.product };


          this.$store.dispatch("settingProductsListInMain", { products: data, totalCount : res.totalCount })


          this.$router.push({ name: "MainProductsPage" })
          // this.$router.push({name: "MainProductsPage", params : {keyword : this.keyword, commonCode : this.choseCategory}})

        })
    },


    slideBack(){
      this.slideIndex = this.slideIndex - 1
      if(this.slideIndex < 0){
        this.slideIndex = 2;
      }

      console.log(this.slideIndex)

    },
    slideFront(){
      this.slideIndex = this.slideIndex + 1
      if(this.slideIndex > 2){
        this.slideIndex = 0;
      }

      console.log(this.slideIndex)


    },
    //이미지 변환 함수
    changeSlide(){
      console.log("자동 실행?", this.topSlideNum)



      setInterval(()=>{

        this.topSlideNum = this.topSlideNum + 1

        if(this.topSlideNum < 2){
          this.topSlideNumCheck(this.topSlideNum)

        } else if(this.topSlideNum >= 3){
          this.topSlideNumCheck(0)

        }

      }, 5000);


    }


  },
  created() {
    // alert("현재 사이트 리뉴얼로 인한 데이터 이관작업이 진행되고 있어서 2~3일정도 일부 서비스에 장애가 발생할 수 있습니다.")
    console.log('MainPage.created');

  },
  mounted() {
    console.log('MainPage.mounted');

    this.changeSlide();


    // this.slideFnc()
  },
  beforeDestroy() {
    console.log('MainPage.beforeDestroy');
  },
};
</script>
<style>


.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.active {
  display: block !important;
}

.liActive {
  background-color: #fff !important;
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, .5);
}


</style>