import Vue from "vue";
import ResultCode from "@/service/ResultCode";

export class Utils {
  preApiHandle(response) {
    console.log("utils.preApiHandle :::::::", response);
    let status = true;
    this.session = response.session;

    if(response.code == ResultCode.NotExistSession) {


      alert("로그인이 필요합니다.");

      location.href = "/"


      return;
    }

	if(response.ExistSession == true) {
		console.log("session exist");
	} else {
		console.log("session empty");

	}
    console.log(this.session);
    return status;
  }
  session = null;
}
const _utils = new Utils();
Utils.install = function (Vue /* options?: any */) {
  Vue.utils = _utils;
  Object.defineProperties(Vue.prototype, {
    $utils: {
      get() {
        return _utils;
      },
    },
    utils: {
      get() {
        return _utils;
      },
    },
  });
};

Vue.use(Utils);
export default Utils;
