


<template>
  <body class="common-home res layout-2">
<!--    <router-view />-->
<div id="wrapper" class="wrapper-fluid banners-effect-7">
  <header id="header" class=" typeheader-2">

    <header-view />

  </header>

  <!-- Main Container  -->
  <div >
    <router-view />
<!--    <login-page />-->
  </div>
  <!-- //Main Container -->

  <!-- Footer Container -->
  <footer-view />
  <!-- //end Footer Container -->

</div>




<!--    <home-layout />-->
  </body>
</template>


<script type="text/javascript" src="@/assets/js/bootstrap.min.js"></script>
<script type="text/javascript" src="@/assets/js/owl-carousel/owl.carousel.js"></script>
<script type="text/javascript" src="@/assets/js/themejs/libs.js"></script>
<script type="text/javascript" src="@/assets/js/unveil/jquery.unveil.js"></script>
<script type="text/javascript" src="@/assets/js/countdown/jquery.countdown.min.js"></script>
<script type="text/javascript" src="@/assets/js/dcjqaccordion/jquery.dcjqaccordion.2.8.min.js"></script>
<script type="text/javascript" src="@/assets/js/datetimepicker/moment.js"></script>
<script type="text/javascript" src="@/assets/js/datetimepicker/bootstrap-datetimepicker.min.js"></script>
<script type="text/javascript" src="@/assets/js/jquery-ui/jquery-ui.min.js"></script>
<script type="text/javascript" src="@/assets/js/modernizr/modernizr-2.6.2.min.js"></script>
<script type="text/javascript" src="@/assets/js/minicolors/jquery.miniColors.min.js"></script>


<script type="text/javascript" src="@/assets/js/themejs/application.js"></script>

<script type="text/javascript" src="@/assets/js/themejs/homepage.js"></script>

<script type="text/javascript" src="@/assets/js/themejs/toppanel.js"></script>
<script type="text/javascript" src="@/assets/js/themejs/so_megamenu.js"></script>
<script type="text/javascript" src="@/assets/js/themejs/addtocart.js"></script>

<script>
import HeaderView from "@/views/common/HeaderView";
import FooterView from "@/views/common/FooterView";
// import LoginPage from "@/views/company/LoginPage";


export default {
  name: "HomeLayout",
  components : {
    'HeaderView' : HeaderView,
    'FooterView' : FooterView,
    // LoginPage
  },
  created() {
    console.log("HomeLayout.created");
  },
  mounted() {
    console.log("HomeLayout.mounted");
    let jsScript = document.createElement('script');
    jsScript.setAttribute('src', 'https://code.jquery.com/jquery-1.12.4.min.js');
    document.head.appendChild(jsScript)


  },
  beforeDestroy() {
    console.log("HomeLayout.beforeDestroy");
  }
}
</script>



<style>
/* Libs Css*/
@import "../src/assets/css/bootstrap/css/bootstrap.min.css";
@import "../src/assets/js/datetimepicker/bootstrap-datetimepicker.min.css";
@import "../src/assets/js/owl-carousel/owl.carousel.css";
@import "../src/assets/css/themecss/lib.css";
@import "../src/assets/js/jquery-ui/jquery-ui.min.css";

@import "../src/assets/js/minicolors/miniColors.css";

@import "../src/assets/css/themecss/so_searchpro.css";
@import "../src/assets/css/themecss/so_megamenu.css";
@import "../src/assets/css/themecss/so-categories.css";
@import "../src/assets/css/themecss/so-listing-tabs.css";
@import "../src/assets/css/themecss/so-newletter-popup.css";



@import "../src/assets/css/footer/footer1.css";
@import "../src/assets/css/footer/footer2.css";
@import "../src/assets/css/header/header1.css";
@import "../src/assets/css/header/header2.css";
@import "../src/assets/css/home2.css";
@import "../src/assets/css/responsive.css";
@import "../src/assets/css/theme.css";


@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.css);
</style>
<style type="text/css">
body{font-family:'Roboto', sans-serif}
</style>