export const payModalStoreActions = {
  setPayappModal : function({commit}, {price, visible}){
    console.log(price)
    console.log(visible)
    commit("price", {price : price});
    commit("visible", {visible : visible});

  },

}
