import Vue from 'vue';
import Vuex from 'vuex';
import { loginStore } from '@/store/loginStore';
import { productsStore } from '@/store/productsStore';
import { userModalStore } from '@/store/userModalStore';
import { payModalStore } from '@/store/payModalStore';
import { paginateStore } from '@/store/paginateStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loginStore,
    productsStore,
    userModalStore,
    payModalStore,
    paginateStore,
  },
});
