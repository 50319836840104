export const loginStoreActions = {
  settingLogin({commit}, {companyCode, userEmail, insertDt, deleteYn}){

    console.log(userEmail)

    commit("companyCode", {companyCode : companyCode})
    commit("userEmail", {userEmail : userEmail })
    commit("insertDt", {insertDt : insertDt })
    commit("deleteYn", {deleteYn : deleteYn })
  },

}
