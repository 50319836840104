<template>
  <div class="row" style="width: 100%; margin-top: 20px;">

    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" >
      <div class="module so-deals-ltr deals-layout2" style=" width: 100%; overflow: hidden;">
        <h3 class="modtitle"><span>이번주 핫딜</span></h3>
        <div class="modcontent">
          <div id="so_deal_1" class="so-deal style2">
            <div class="extraslider-inner products-list yt-content-slider"  data-rtl="yes" data-autoplay="no" data-autoheight="no" data-delay="4" data-speed="0.6" data-margin="30" data-items_column0="1" data-items_column1="1" data-items_column2="2"  data-items_column3="1" data-items_column4="1" data-arrows="yes" data-pagination="no" data-lazyload="yes" data-loop="no" data-hoverpause="yes">
              <div class="owl2-stage-outer">
                <div class="owl2-stage thisWeekBest" style="    transform: translate3d(0px, 0px, 0px); transition: all 0s ease 0s;">
                  <div class="owl2-item " :class="{'active':thisWeekFamous == 0}" style="width: 100%; margin-right: 30px; display: none;">
                    <div class="item">
                      <div class="product-thumb">
                        <div class="row">
                          <div class="inner">
                            <div class="item-left col-lg-6 col-md-5 col-sm-5 col-xs-12">
                              <div class="image product-item-container">
<!--                                <div class="box-label" v-if="hotNewIcon"> <span class="label-product label-new"> 신규 </span></div>-->
<!--                                <span class="label-product label-product-sale"><i class="fa fa-won" v-if="hotType"></i>{{ hotDiscountType }}<i class="fa fa-percent" v-if="!hotType"></i>-->
<!--                                </span>-->
                                <div class="left-block">
                                  <div class="product-image-container second_img" v-if="hotLink != null && hotLink.indexOf('http') != -1">
                                    <a :href="hotLink" target="_blank">
                                      <img v-if="hotImage != undefined && hotImage != null" :src="imagePathHot(hotImage, hotImageList)"  class="img-responsive img-1" style="min-width: 500px; height: 500px;">
                                      <img v-if="hotImage === undefined" :src="imagePathHot(hotImage, hotImageList)"  class="img-responsive img-1" style="min-width: 500px; height: 500px;">
                                    </a>
                                  </div>
                                  <div class="product-image-container second_img" v-if="hotLink == null || hotLink.indexOf('http') == -1">
                                    <div @click="toProductDetailPage">
                                      <img v-if="hotImage != undefined && hotImage != null" :src="imagePathHot(hotImage, hotImageList)"  class="img-responsive img-1" style="min-width: 500px; height: 500px;">
                                      <img v-if="hotImage === undefined" :src="imagePathHot(hotImage, hotImageList)"  class="img-responsive img-1" style="min-width: 500px; height: 500px;">

                                    </div>
                                  </div>
                                </div>



<!--                                <div class="button-group so-quickview">-->
<!--                                  <button class="btn-button addToCart" title="Add to Cart" type="button" onclick="cart.add('69');"><i class="fa fa-shopping-basket"></i>  <span>Add to Cart</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button wishlist" type="button" title="Add to Wish List" onclick="wishlist.add('69');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button compare" type="button" title="Compare this Product" onclick="compare.add('69');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
<!--                                  </button>-->
<!--                                  &lt;!&ndash;quickview&ndash;&gt;-->
<!--                                  <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0)" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
<!--                                  &lt;!&ndash;end quickview&ndash;&gt;-->
<!--                                </div>-->
                              </div>
                            </div>
                            <div class="item-right col-lg-6 col-md-7 col-sm-7 col-xs-12 right-block">
                              <div class="caption">
                                <h4 v-if="hotLink != null && hotLink.indexOf('http') != -1">
                                  <a :href="hotLink" target="_blank" :title="hotName">{{ hotName }}</a>
                                </h4>
                                <h4 v-if="hotLink == null || hotLink.indexOf('http') == -1">
                                  <a href="javascript:void(0);" :title="hotName" @click="toProductDetailPage">{{ hotName }}</a>
                                </h4>
<!--                                <p class="price">   <span class="price-new"><i class="fa fa-won"></i> {{ priceConverting(hotDiscountPrice) }}</span>-->
<!--                                  <span class="price-old"><i class="fa fa-won" ></i> {{ priceConverting(hotOriginPrice) }} </span>-->
<!--                                </p>-->

<!--                                <div class="item-available">-->
<!--                                  <div class="row">-->
<!--                                    <p class="col-xs-6 a1">Available: <b>98</b>-->
<!--                                    </p>-->
<!--                                    <p class="col-xs-6 a2">Sold: <b>32</b>-->
<!--                                    </p>-->
<!--                                  </div>-->
<!--                                  <div class="available"> <span class="color_width" data-title="75%" data-toggle="tooltip" title="75%" style="width: 75%"></span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;countdown box&ndash;&gt;-->
<!--                                <div class="item-time-w">-->
<!--                                  <div class="time-title"><span></span> Offer ends in:</div>-->
<!--                                  <div class="item-time">-->
<!--                                    <div class="item-timer">-->
<!--                                      &lt;!&ndash;                                                  offer 에 대한 시간 줄여들으는 부분 &ndash;&gt;-->
<!--                                      <div class="defaultCountdown-30">-->
<!--                                        <div  class="time-item time-day">-->
<!--                                          <div class="num-time">00</div>-->
<!--                                          <div class="name-time">일</div>-->

<!--                                        </div>-->
<!--                                        <div  class="time-item time-hour">-->
<!--                                          <div class="num-time">00</div>-->
<!--                                          <div class="name-time">시간</div>-->

<!--                                        </div>-->
<!--                                        <div  class="time-item time-min">-->
<!--                                          <div class="num-time">00</div>-->
<!--                                          <div class="name-time">분</div>-->

<!--                                        </div>-->
<!--                                        <div  class="time-item time-sec">-->
<!--                                          <div class="num-time">00</div>-->
<!--                                          <div class="name-time">초</div>-->

<!--                                        </div>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
                                <!--end countdown box-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

<!--                  <div class="owl2-item "  :class="{'active':thisWeekFamous == 1}"  style="width: 100%;  display: none;">-->
<!--                    <div class="item">-->
<!--                      <div class="product-thumb ">-->
<!--                        <div class="row">-->
<!--                          <div class="inner">-->
<!--                            <div class="item-left col-lg-6 col-md-5 col-sm-5 col-xs-12">-->
<!--                              <div class="image">-->
<!--                                                            <span class="label-product label-product-sale">-->
<!--                                                                -10%-->
<!--                                                            </span>-->

<!--                                <img src="../../assets/image/catalog/demo/product/index2/2.jpg" alt="Xancetta bresao" class="img-responsive">-->

<!--                                <div class="button-group so-quickview">-->
<!--                                  <button class="btn-button addToCart" title="Add to Cart" type="button" onclick="cart.add('75');"><i class="fa fa-shopping-basket"></i>  <span>Add to Cart</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button wishlist" type="button" title="Add to Wish List" onclick="wishlist.add('75');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button compare" type="button" title="Compare this Product" onclick="compare.add('75');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
<!--                                  </button>-->
<!--                                  &lt;!&ndash;quickview&ndash;&gt;-->
<!--                                  <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0)" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
<!--                                  &lt;!&ndash;end quickview&ndash;&gt;-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="item-right col-lg-6 col-md-7 col-sm-7 col-xs-12">-->
<!--                              <div class="caption">-->
<!--                                <h4><a href="#" target="_self" title="Xancetta bresao">Xancetta bresao</a></h4>-->
<!--                                <p class="price">   <span class="price-new">$80.00</span>-->
<!--                                  <span class="price-old">$89.00</span>-->
<!--                                </p>-->

<!--                                <div class="item-available">-->
<!--                                  <div class="row">-->
<!--                                    <p class="col-xs-6 a1">Available: <b>555</b>-->
<!--                                    </p>-->
<!--                                    <p class="col-xs-6 a2">Sold: <b>0</b>-->
<!--                                    </p>-->
<!--                                  </div>-->
<!--                                  <div class="available"> <span class="color_width" data-title="0%" data-toggle="tooltip" title="75%" style="width: 0%"></span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;countdown box&ndash;&gt;-->
<!--                                <div class="item-time-w">-->
<!--                                  <div class="time-title"><span>Hurry Up!</span> Offer ends in:</div>-->
<!--                                  <div class="item-time">-->
<!--                                    <div class="item-timer">-->
<!--                                      <div class="defaultCountdown-30"></div>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                </div>-->
<!--                                &lt;!&ndash;end countdown box&ndash;&gt;-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="owl2-item " :class="{'active':thisWeekFamous == 2}"  style="width: 100%;  display: none;">-->
<!--                    <div class="item">-->
<!--                      <div class="product-thumb transition ">-->
<!--                        <div class="row">-->
<!--                          <div class="inner">-->
<!--                            <div class="item-left col-lg-6 col-md-5 col-sm-5 col-xs-12">-->
<!--                              <div class="image">-->
<!--                                <span class="label-product label-product-sale">-17%</span>-->
<!--                                <img src="../../assets/image/catalog/demo/product/index2/16.jpg" alt="Wamboudin ribeye" class="img-responsive">-->
<!--                                <div class="button-group so-quickview">-->
<!--                                  <button class="btn-button addToCart" title="Add to Cart" type="button" onclick="cart.add('79');"><i class="fa fa-shopping-basket"></i>  <span>Add to Cart</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button wishlist" type="button" title="Add to Wish List" onclick="wishlist.add('79');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
<!--                                  </button>-->
<!--                                  <button class="btn-button compare" type="button" title="Compare this Product" onclick="compare.add('79');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
<!--                                  </button>-->
<!--                                  &lt;!&ndash;quickview&ndash;&gt;-->
<!--                                  <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0)" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
<!--                                  &lt;!&ndash;end quickview&ndash;&gt;-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="item-right col-lg-6 col-md-7 col-sm-7 col-xs-12">-->
<!--                              <div class="caption">-->
<!--                                <h4><a href="#" target="_self" title="Wamboudin ribeye">Wamboudin ribeye</a></h4>-->
<!--                                <p class="price">   <span class="price-new">$70.00</span>-->
<!--                                  <span class="price-old">$84.00</span>-->
<!--                                </p>-->

<!--                                <div class="item-available">-->
<!--                                  <div class="row">-->
<!--                                    <p class="col-xs-6 a1">Available: <b>100</b>-->
<!--                                    </p>-->
<!--                                    <p class="col-xs-6 a2">Sold: <b>60</b>-->
<!--                                    </p>-->
<!--                                  </div>-->
<!--                                  <div class="available"> <span class="color_width" data-title="63%" data-toggle="tooltip" title="63%" style="width: 63%"></span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;countdown box&ndash;&gt;-->
<!--                                <div class="item-time-w">-->
<!--                                  <div class="time-title"><span>Hurry Up!</span> Offer ends in:</div>-->
<!--                                  <div class="item-time">-->
<!--                                    <div class="item-timer">-->
<!--                                      <div class="defaultCountdown-30"></div>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                </div>-->
<!--                                &lt;!&ndash;end countdown box&ndash;&gt;-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </div>-->
                </div>
              </div>
              <div class="owl2-controls" style="display: none;">
                <div class="owl2-nav">
                  <div class="owl2-prev" @click="toThisWeekBest(-1)">
                  </div>
                  <div class="owl2-next" @click="toThisWeekBest(+1)">
                  </div>
                </div>
                <div class="owl2-dots" >
                </div>
              </div>


            </div>
          </div>

        </div>

      </div>
    </div>



    <!--                베스트 셀러 -- 나중에 변경하기 -->


    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <!-- Listing tabs -->
      <div class="module listingtab-layout2">
        <h3 class="modtitle"><span>베스트 셀러</span></h3>
        <div id="so_listing_tabs_1" class="so-listing-tabs first-load">
          <div class="ltabs-wrap">

<!--            <div class="ltabs-tabs-container" data-rtl="yes" data-delay="300" data-duration="600" data-effect="starwars" data-ajaxurl="" data-type_source="0" data-lg="4" data-md="3" data-sm="2" data-xs="1" data-margin="0" >-->
<!--              &lt;!&ndash;Begin Tabs&ndash;&gt;-->
<!--              <div class="ltabs-tabs-wrap ltabs-selectbox">-->
<!--                <span class="ltabs-tab-selected" @click="clickBestSellingSelectBox">{{bestSellingCateg}}</span> <span class="ltabs-tab-arrow"  @click="clickBestSellingSelectBox">▼</span>-->
<!--                <div class="item-sub-cat">-->
<!--                  <ul class="ltabs-tabs cf" :class="{'active' : bestSellingSelect}" style="display: none;">-->
<!--                    <li class="ltabs-tab" data-category-id="20" data-active-content=".items-category-20" v-for="(category, index) in getCategories" :key="index" style="display: block;" @click="bestSellingCategory(category.commonCode, category.commonName)">-->
<!--                      <span class="ltabs-tab-label">{{ category.commonName }}</span>-->
<!--                    </li>-->
<!--                    &lt;!&ndash;                                <li class="ltabs-tab " data-category-id="18" data-active-content=".items-category-18"> <span class="ltabs-tab-label">Fashion</span> </li>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <li class="ltabs-tab " data-category-id="25" data-active-content=".items-category-25"> <span class="ltabs-tab-label">Electronics</span> </li>&ndash;&gt;-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; End Tabs&ndash;&gt;-->
<!--            </div>-->

            <div class="products-list row nopadding-xs so-filter-gird grid" style="margin: auto;">

              <div class="ltabs-items ltabs-items-selected items-category-20" data-total="8">
                <div class="ltabs-items-inner ltabs-slider">
                  <!-- item listing tab -->
                  <div class="ltabs-item" v-for="(each, index) in bestSellingList" :key="index" style="    width: 250px; margin-right: 15px; float: left; ">
                    <div class="item-inner product-layout transition product-grid " style=" ">
                      <div class="product-item-container second_img">
                        <div class="left-block">
                          <div class="product-image-container second_img" v-if="each.link != null && each.link.indexOf('http') != -1">
                            <a :href="each.link" target="_blank" >
                              <img :src="imagePath(each.images, each.imgList)" class="img-1 img-responsive" style=" width: 270px; height: 270px;">
                              <img :src="imagePath(each.images, each.imgList)" class="img-2 img-responsive" style=" width: 270px; height: 270px;">
                            </a>
                          </div>
                          <div class="product-image-container second_img" v-if="each.link == null || each.link.indexOf('http') == -1">
                            <a href="javascript:void(0)" @click="toProductDetailPage(each.productsCode)">
                              <img :src="imagePath(each.images, each.imgList)" class="img-1 img-responsive" style=" width: 270px; height: 270px;">
                              <img :src="imagePath(each.images, each.imgList)" class="img-2 img-responsive" style=" width: 270px; height: 270px;">
                            </a>
                          </div>
<!--                          <div class="box-label" v-if="newLabel(each.insertDt) < 8"> <span class="label-product label-new"> 신규 </span></div>-->
<!--                          <div class="box-label"><span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="each.discountPrice != 0"><i class="fa fa-won" ></i>{{ priceConverting(each.discountPrice) }}</span><span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="each.discountPercent != 0"> {{each.discountPercent}}<i class="fa fa-percent" ></i></span></div>-->
<!--                          <div class="button-group so-quickview cartinfo&#45;&#45;left">-->
<!--                            <button type="button" class="addToCart btn-button" title="Add to cart" onclick="cart.add('60 ');">  <i class="fa fa-heart"></i>-->
<!--                              <span>Add to cart </span>-->
<!--                            </button>-->
<!--                            <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
<!--                            </button>-->
<!--                            <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
<!--                            </button>-->
<!--                            &lt;!&ndash;quickview&ndash;&gt;-->
<!--                            <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
<!--                            &lt;!&ndash;end quickview&ndash;&gt;-->
<!--                          </div>-->
                        </div>
                        <div class="right-block">
                          <div class="caption">
<!--                            <div class="rating">    <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                              <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                              <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                              <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                              <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                            </div>-->
                            <h4 v-if="each.link != null && each.link.indexOf('http') != -1">
                              <a :href="each.link" target="_blank">{{ cutTitle(each.productsName) }}</a>
                            </h4>
                            <h4 v-if="each.link == null || each.link.indexOf('http') == -1">
                              <a href="javascript:void(0)" @click="toProductDetailPage(each.productsCode)">{{ cutTitle(each.productsName) }}</a>
                            </h4>
<!--                            <div class="price"><i class="fa fa-won"></i> {{ priceConverting(each.sellPrice) }}</div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";

var interval;

export default {
  name: "MainBestSellerPage",
  data(){
    return{
      bestSellingSelect : false,
      bestSellingCateg : '-- 선택 --',
      bestSellingList : null,
      topSlideNum : 0,
      thisWeekFamous : 0,
      shopWithCategory : 6,

      bestList : null,

      hotCode : null,
      hotName : null,
      hotImage : null,
      hotImageList : null,
      hotOriginPrice : null,
      hotType : false,
      hotDiscountPrice : null,
      hotDiscountType : null,
      hotNewIcon : false,
      hotLink : null,


      // topScrollImgs : {
      //   width: '2110px',
      //   marginRight: '0px'
      // }
    }
  },
  computed : {
    ...mapGetters([
      'getCategories'
    ]),
  },

  methods : {
    cutTitle(title){

      if(title.length > 24){
        return title.substr(0, 22) + "..";
      } else {
        return title;
      }

    },
    toProductDetailPage(value){

      // console.log(this.hotCode, "//", value)

      if(this.hotCode != null){
        this.$router.push({name: "MainProductsDetailPage", params : {"productsCode" : this.hotCode}})

      } else {
        this.$router.push({name: "MainProductsDetailPage", params : {"productsCode" : value}})

      }
    },
    newLabel(value){

      var insertDt = new Date(value);
      var today = new Date();

      var diff = Math.abs(today.getTime() - insertDt.getTime());
      diff = Math.abs(diff / (1000 * 3600 * 24));

      return diff;

    },
    imagePathHot(value, list){
      console.log("main best seller page value", value, list);

      if(value != undefined && value.length > 2){
        return this.siteUrl + "/common/showImage?code=" + value[0].code + "&seq=5";
      }
      if(list != undefined && list != null){
        return "http://pang.frients.ai/modupang/" + list;


      } else {
        return "/assets/images/catalog/demo/product/index2/8.jpg";
      }

    },
    imagePath(value, list){
      console.log("main best seller page value", value, list);

      if(value != undefined && value.length > 2){
        return this.siteUrl + "/common/showImage?code=" + value[0].code + "&seq=1";
      }
      if(list != undefined && list != null){
        return "http://pang.frients.ai/modupang/" + list;


      } else {
        return "/assets/images/catalog/demo/product/index2/8.jpg";
      }

    },
    priceConverting(value){
      if(value > 0){
        return value.toLocaleString('ko-KR');

      } else {
        return 0;
      }
    },
    clickBestSellingSelectBox(){
      this.bestSellingSelect = !this.bestSellingSelect
    },

    bestSellingCategory(value, text){
      this.bestSellingCateg = text
      console.log(value)

      this.clickBestSellingSelectBox();
    },
    topSlideNumCheck(num){

      this.topSlideNum = num;

    },
    sendCategorySelect(value){


      console.log(":dsfsds")

      var param = {
        keyword : '',
        commonCode : value,
        pageNum : '15',
        skipNum : '0',

      }

      this.api.products(param)
        .then((res)=> {
          console.log(res)

          var data = { keyword: this.keyword, commonCode: this.choseCategory, productsList: res.product };


          this.$store.dispatch("settingProductsListInMain", { products: data,  totalCount : res.totalCount })


          this.$router.push({ name: "MainProductsPage" })
          // this.$router.push({name: "MainProductsPage", params : {keyword : this.keyword, commonCode : this.choseCategory}})

        })
    },
    toThisWeekBest(num){
      console.log(this.thisWeekFamous)
      this.thisWeekFamous = this.thisWeekFamous + num

      // if(this.thisWeekFamous < 0){
      //   this.thisWeekFamous = 0;
      // }
      if(this.thisWeekFamous > 2){
        this.thisWeekFamous = 0
      }



    },
    getNewHotDeal(){
      var parma = {}

      this.api.randomOneProduct(parma)
        .then((res)=>{
          console.log("hot deal", res);

          this.hotCode = res.product.productsCode;
          this.hotName = res.product.productsName;
          console.log(res.product.imgList);


          // this.hotImage = "http://pang.frients.ai/modupang/" + res.product.imgList;


          this.hotImage = res.product.images;
          this.hotImageList = res.product.imgList;

          // if(res.product.imgList != undefined && res.product.imgList != "" && res.product.imgList != "-"){
          //   var split = res.product.imgList.split(",");
          //
          //   if(split.length == 5){
          //     this.hotImage = "https://modupang-products.s3.ap-northeast-2.amazonaws.com/" + split[4]
          //
          //   } else {
          //     this.hotImage = "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/600.jpg";
          //   }
          //
          //
          //
          // } else {
          //   this.hotImage = "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/600.jpg";
          // }

          this.hotOriginPrice = res.product.defaultPrice;

          this.hotDiscountPrice = res.product.sellPrice;


          if(res.product.discountPrice == "0"){
            this.hotType = false;
            this.hotDiscountType = res.product.discountPercent;
          } else if(res.product.discountPercent == "0"){
            this.hotType = true;

            this.hotDiscountType = res.product.discountPrice;
          }


          // var insertDt = new Date(res.product.insertDt);
          // var today = new Date();
          //
          // var diff = Math.abs(today.getTime() - insertDt.getTime());
          // diff = Math.abs(diff / (1000 * 3600 * 24));

          var diff = this.newLabel(res.product.insertDt)

          if(diff < 7){
            this.hotNewIcon = true;
          } else {
            this.hotNewIcon = false;
          }

          if(res.product.link != "" && res.product.link != null && res.product.link != undefined){
            this.hotLink = res.product.link;
          } else {
            this.hotLink = null;

          }


        })
    },
    autoChangeHotDeal(){


      interval = setInterval(()=>{
        // console.log("새로운 데이터 가지고 오기")



      // this.toThisWeekBest(1)
        this.getNewHotDeal();

      }, 30000);


    },
    getBestSeller(){
      var param = {}

      this.api.oneProductByCategory(param)
      .then((res)=>{
        console.log("dddd",res)

        this.bestSellingList = res.product
      })
    },




  },
  created(){
    this.getNewHotDeal();
    this.getBestSeller();

  },
  mounted(){


  this.autoChangeHotDeal();
    //카테고리 별로 제일 많이 클릭되어진 목록을 출력 ..
    // var param = {
    //
    // }
    //
    // this.api.getBestSellerList(param)
    // .then(()=>{
    //
    //   // this.bestList
    // })
  },
  beforeDestroy() {
    clearInterval(interval)
  }
};
</script>

<style scoped>

</style>