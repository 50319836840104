import { userModalStoreActions } from './userModalStoreActions';
export const userModalStore = {
  namespaced: true,
  state: {
    visible: '', // null
    count: '', // null
  },
  getters: {
    visible: state => {
      if (state.visible === undefined || state.visible === null || state.visible === '') {
        const jsonStr = localStorage.getItem('visible');
        if (jsonStr === undefined || jsonStr === null) {
          state.visible = null;
          return state.visible;
        } else {
          state.visible = JSON.parse(jsonStr);
        }
      }
      return state.visible;
    },
    count: state => {
      if (state.count === undefined || state.count === null || state.count === '') {
        const jsonStr = localStorage.getItem('count');
        if (jsonStr === undefined || jsonStr === null) {
          state.count = null;
          return state.count;
        } else {
          state.count = JSON.parse(jsonStr);
        }
      }
      return state.count;
    },
  },
  mutations: {
    visible: (state, visible) => {
      if (visible === undefined || visible === null) {
        localStorage.removeItem('visible');
      } else {
        localStorage.setItem('visible', JSON.stringify(visible));
      }
      state.visible = visible;
    },
    count: (state, count) => {
      if (count === undefined || count === null) {
        localStorage.removeItem('count');
      } else {
        localStorage.setItem('count', JSON.stringify(count));
      }
      state.count = count;
    },
  },
  actions: userModalStoreActions,
};
