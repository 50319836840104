import Vue from 'vue';
export class ApiService {
  setAccessToken(accessToken) {
    Vue.axios.defaults.headers.common['Authorization'] = accessToken;
  }
  login(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/login', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  main(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/main', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  signIn(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/signIn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  emailCheck(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/emailCheck', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  updateUserProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/updateUserProfile', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserTask(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/getUserTask', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUsingList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/plan/selectPlan2', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
        .catch((err) => {
          reject(err);
        })
    });
  }
  signOut(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/signOut', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/getUserProfile', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getPlanList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/getPlanList', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  category(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/category/category', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  products(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/category/products', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  newProductsList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/category/newProductsList', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  randomOneProduct(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/category/randomOneProduct', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  oneProductByCategory(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/category/oneProductByCategory', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  productList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/product/productList', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  productAdd(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/product/productAdd', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  productDelete(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/product/productDelete', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  productEach(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/product/productEach', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  productSetPublic(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/product/productSetPublic', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
}
const _api = new ApiService();
ApiService.install = function (Vue /* options?: any */) {
  Vue.api = _api;
  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return _api;
      },
    },
    api: {
      get() {
        return _api;
      },
    },
  });
};

Vue.use(ApiService);

export default ApiService;
