<template>
  <div>

    <div class="main-container container">
      <ul class="breadcrumb">
        <li><router-link to="/"><i class="fa fa-home"></i></router-link></li>
        <li><a href="javascript:void(0)">상품 관리</a></li>
        <li><a href="javascript:void(0)">상품 등록</a></li>

      </ul>

      <div class="row">
        <!--Middle Part Start-->
        <div id="content" class="col-sm-12">
          <!--          <h2 class="title">Checkout</h2>-->
          <div class="so-onepagecheckout row">

            <h3>&nbsp;&nbsp;<b>이벤트 등록</b></h3>

            <div class="col-left col-sm-6">
              <!--              <div class="panel panel-default">-->
              <!--                <div class="panel-heading">-->
              <!--                  <h4 class="panel-title"><i class="fa fa-sign-in"></i> Create an Account or Login</h4>-->
              <!--                </div>-->
              <!--                <div class="panel-body">-->
              <!--                  <div class="radio">-->
              <!--                    <label>-->
              <!--                      <input type="radio" value="register" name="account">-->
              <!--                      Register Account</label>-->
              <!--                  </div>-->
              <!--                  <div class="radio">-->
              <!--                    <label>-->
              <!--                      <input type="radio" checked="checked" value="guest" name="account">-->
              <!--                      Guest Checkout</label>-->
              <!--                  </div>-->
              <!--                  <div class="radio">-->
              <!--                    <label>-->
              <!--                      <input type="radio" value="returning" name="account">-->
              <!--                      Returning Customer</label>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->


              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-cube"></i> 상품 등록</h4>
                </div>
                <div class="panel-body">
                  <fieldset id="account">
                    <div class="form-group required">
                      <label for="input-payment-firstname" class="control-label">상품명</label>
                      <input type="text" class="form-control" id="input-payment-firstname" placeholder="상품명" value="" name="" v-model="productsName">
                    </div>



                    <div class="form-group ">
                      <label for="input-payment-lastname" class="control-label">링크</label>
                      <input type="text" class="form-control" id="input-payment-lastname" placeholder="상품 링크" value=""  v-model="link">
                    </div>
                    <div class="form-group required">
                      <label for="input-payment-telephone" class="control-label">원가</label>
                      <input type="text" class="form-control" id="input-payment-telephone" placeholder="원가" value="" v-model="defaultPrice" >
                    </div>
                    <div class="form-group \">
                      <label for="input-payment-email" class="control-label">재고</label>
                      <input type="text" class="form-control" id="input-payment-email" placeholder="재고" value="" v-model="stockAmount" >
                    </div>
                    <br>
                    <!--                    <div class="form-group">-->
                    <!--                      <label for="input-payment-fax" class="control-label">Fax</label>-->
                    <!--                      <input type="text" class="form-control" id="input-payment-fax" placeholder="Fax" value="" name="fax">-->
                    <!--                    </div>-->
                  </fieldset>
                </div>
              </div>
            </div>
            <div  class="col-right col-sm-6">






              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-sign-in"></i> 금액 설정 </h4>
                </div>
                <div class="panel-body"  >
                  <div class="radio">
                    <label>
                      <input type="radio" value="1" name="account" v-model="discountType">
                      가격 차감</label>
                  </div>
                  <div class="radio">
                    <label>
                      <input type="radio" checked="checked" value="2" name="account" v-model="discountType">
                      퍼센트 할인</label>
                  </div>
                  <div class="form-group required">
                    <label for="input-payment-fax" class="control-label" v-if="discountType == 1" >할인 금액</label>
                    <label for="input-payment-fax" class="control-label"  v-if="discountType == 2">할인율</label>
                    <input type="text" class="form-control" id="input-payment-fax" v-model="discountMoney">
                  </div>

                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-pencil"></i> 상품 설명</h4>
                </div>
                <div class="panel-body">
                  <textarea rows="4" class="form-control" id="confirm_comment" name="comments" v-model="productsText"></textarea>
                  <!--                  <label class="control-label" for="confirm_agree">-->
                  <!--                    <input type="checkbox" checked="checked" value="1" required="" class="validate required" id="confirm_agree" name="confirm agree">-->
                  <!--                    <span>I have read and agree to the <a class="agree" href="#"><b>Terms &amp; Conditions</b></a></span> </label>-->
                  <!--                  <div class="buttons">-->
                  <!--                    <div class="pull-right">-->
                  <!--                      <input type="button" class="btn btn-primary" id="button-confirm" value="Confirm Order">-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>





            </div>


            <div  class="col-left col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-sign-in"></i> 카테고리 <small>* 카테고리를 등록하지 않으면 조회가 되지 않을 수 있습니다.</small></h4>
                </div>
                <div class="panel-body">
                  <div class="checkbox" v-for="(each, index) in categoryList" :key="index">
                    <label>
                      <input type="checkbox" :value="each.commonCode" name="account" v-model="categoryCheck" >
                      {{each.commonName}}
                    </label>
                  </div>




                </div>
              </div>

              <hr>
              <h3><b>이미지 등록</b> <small> * 이미지는 사이즈별로 꼭 등록을 해주세요. 5장을 모두 등록.  </small></h3>
              <button v-if="addMewImage === true" type="button" class="btn btn-info" @click="deleteMultiMedia">모든 이미지 삭제</button>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-book"></i> 270*270 이미지 (2장을 등록해주세요.)</h4>
                </div>
                <div class="panel-body">
                  <div class="panel-body row">
                    <div class="col-sm-4 ">
                      <div class="input-group" style="text-align: center;">
                        <div class="left-block">
                          <div class="left-block" v-if="imageLists != null">

                            <div class="product-image-container ">
                              <img :src="this.siteUrl + '/common/showImage?type=thumbnail&code=' + imageLists[0].code + '&seq=1'" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">
                            </div>
                          </div>
                        </div>
                        <!--                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">-->
<!--                        <input  v-if="addMewImage === false"  type="button" class="btn btn-default" id="button-coupon1" value="메인 이미지 추가" @click="activeFileForm(0)">-->
                        <span class="input-group-btn">
<!--                          <input type="file" ref="fileOne"  @change="addImage(0)" style="display: none;" />-->
                           <input v-if="addMewImage === false" @change="addImage(0)" ref="fileOne" type="file" id="filesOne"  class="filesOne btn btn-default" name="files[]" value="메인 이미지 추가" >
							          </span>
                      </div>
                    </div>
                    <div class="col-sm-4 ">
                      <div class="input-group" style="text-align: center;">
                        <div class="left-block">
                          <div class="left-block"  v-if="imageLists != null">

                            <div class="product-image-container ">
                              <img :src="this.siteUrl + '/common/showImage?type=thumbnail&code=' + imageLists[0].code + '&seq=2'" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">
                            </div>
                          </div>
                        </div>
                        <!--                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">-->
<!--                        <input  v-if="addMewImage === false"  type="button" class="btn btn-default" id="button-coupon2" value="두번째 이미지 추가" @click="activeFileForm(1)">-->
                        <span class="input-group-btn">
                           <input v-if="addMewImage  === false" @change="addImage(1)" ref="fileTwo" type="file" id="filesTwo"  class="filesOne btn btn-default" name="files[]" >
<!--                          <input type="file" ref="fileTwo"  @change="addImage(1)" accept="image/*" style="display: none;" />-->
							          </span>
                      </div>
                    </div>


                    <!--                    <div class="col-sm-4 ">-->
                    <!--                      <div class="input-group" style="text-align: center;">-->
                    <!--                        <div class="left-block">-->
                    <!--                          <div class="left-block">-->

                    <!--                            <div class="product-image-container ">-->
                    <!--                              <img :src="getImgUrlThree" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">&ndash;&gt;-->
                    <!--                        <input type="button" class="btn btn-default" id="button-coupon" value="세번째 이미지 추가" @click="activeFileForm(2)">-->
                    <!--                        <span class="input-group-btn">-->
                    <!--                          <input type="file" ref="fileThree"  @change="addImage(2)" style="display: none;" />-->
                    <!--							          </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                  </div>
                  <!--                  <div class="buttons">-->
                  <!--                    <div class="pull-right">-->
                  <!--                      <button type="button" class="btn btn-primary" id="button-confirm" @click="addProduct">상품 등록</button>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                </div>
              </div>


              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-book"></i> 320*320 이미지 (2장을 등록해주세요.)</h4>
                </div>
                <div class="panel-body">
                  <div class="panel-body row">
                    <div class="col-sm-4 ">
                      <div class="input-group" style="text-align: center;">
                        <div class="left-block">
                          <div class="left-block"  v-if="imageLists != null">

                            <div class="product-image-container ">
                              <img :src="this.siteUrl+'/common/showImage?type=thumbnail&code=' + imageLists[0].code + '&seq=3'" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">
                            </div>
                          </div>
                        </div>
                        <!--                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">-->
<!--                        <input  v-if="addMewImage === false"  type="button" class="btn btn-default" id="button-coupon3" value="메인 이미지 추가" @click="activeFileForm(2)">-->
                        <span class="input-group-btn">
                           <input v-if="addMewImage  === false"  @change="addImage(2)" ref="fileThree" type="file" id="filesThree"  class="filesOne btn btn-default" name="files[]"   >
<!--                          <input type="file" ref="fileThree"  @change="addImage(2)" style="display: none;" />-->
							          </span>
                      </div>
                    </div>
                    <div class="col-sm-4 ">
                      <div class="input-group" style="text-align: center;">
                        <div class="left-block">
                          <div class="left-block"  v-if="imageLists != null">

                            <div class="product-image-container ">
                              <img :src="this.siteUrl + '/common/showImage?type=thumbnail&code=' + imageLists[0].code + '&seq=4'" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">
                            </div>
                          </div>
                        </div>
                        <!--                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">-->
<!--                        <input  v-if="addMewImage === false"  type="button" class="btn btn-default" id="button-coupon4" value="두번째 이미지 추가" @click="activeFileForm(3)">-->
                        <span class="input-group-btn">
                           <input v-if="addMewImage  === false" @change="addImage(3)" ref="fileFour" type="file" id="filesFour"  class="filesOne btn btn-default" name="files[]"  >
<!--                          <input type="file" ref="fileFour"  @change="addImage(3)" accept="image/*" style="display: none;" />-->
							          </span>
                      </div>
                    </div>




                    <!--                    <div class="col-sm-4 ">-->
                    <!--                      <div class="input-group" style="text-align: center;">-->
                    <!--                        <div class="left-block">-->
                    <!--                          <div class="left-block">-->

                    <!--                            <div class="product-image-container ">-->
                    <!--                              <img :src="getImgUrlThree" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">&ndash;&gt;-->
                    <!--                        <input type="button" class="btn btn-default" id="button-coupon" value="세번째 이미지 추가" @click="activeFileForm(2)">-->
                    <!--                        <span class="input-group-btn">-->
                    <!--                          <input type="file" ref="fileThree"  @change="addImage(2)" style="display: none;" />-->
                    <!--							          </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                  </div>
                  <!--                  <div class="buttons">-->
                  <!--                    <div class="pull-right">-->
                  <!--                      <button type="button" class="btn btn-primary" id="button-confirm" @click="addProduct">상품 등록</button>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                </div>
              </div>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title"><i class="fa fa-book"></i> 600*600 이미지 (1장을 등록해주세요.)</h4>
                </div>
                <div class="panel-body">
                  <div class="panel-body row">
                    <div class="col-sm-4 ">
                      <div class="input-group" style="text-align: center;">
                        <div class="left-block">
                          <div class="left-block"  v-if="imageLists != null">

                            <div class="product-image-container ">
                              <img :src="this.siteUrl + '/common/showImage?type=thumbnail&code=' + imageLists[0].code + '&seq=5'" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">
                            </div>
                          </div>
                        </div>
                        <!--                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">-->
<!--                        <input v-if="addMewImage  === false" type="button" class="btn btn-default" id="button-coupon5" value="메인 이미지 추가" @click="activeFileForm(4)">-->
                        <span class="input-group-btn">
                          <input v-if="addMewImage  === false"  @change="addImage(4)" ref="fileFive" type="file" id="filesFive" class="filesOne btn btn-default" name="files[]"   >
<!--                          <input type="file" ref="fileFive"  @change="addImage(4)" style="display: none;" />-->
							          </span>
                      </div>
                    </div>
                    <!--                    <div class="col-sm-4 ">-->
                    <!--                      <div class="input-group" style="text-align: center;">-->
                    <!--                        <div class="left-block">-->
                    <!--                          <div class="left-block">-->

                    <!--                            <div class="product-image-container ">-->
                    <!--                              <img :src="getImgUrlTwo" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">&ndash;&gt;-->
                    <!--                        <input type="button" class="btn btn-default" id="button-coupon2" value="두번째 이미지 추가" @click="activeFileForm(1)">-->
                    <!--                        <span class="input-group-btn">-->
                    <!--                          <input type="file" ref="fileTwo"  @change="addImage(1)" accept="image/*" style="display: none;" />-->
                    <!--							          </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-sm-4 ">-->
                    <!--                      <div class="input-group" style="text-align: center;">-->
                    <!--                        <div class="left-block">-->
                    <!--                          <div class="left-block">-->

                    <!--                            <div class="product-image-container ">-->
                    <!--                              <img :src="getImgUrlThree" class="img-1 img-responsive" alt="Pastrami bacon" style="width:270px; height: 270px;">-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;                        <input type="text" class="form-control" id="input-coupon" placeholder="이미지를 추가하세요" value="" name="coupon">&ndash;&gt;-->
                    <!--                        <input type="button" class="btn btn-default" id="button-coupon" value="세번째 이미지 추가" @click="activeFileForm(2)">-->
                    <!--                        <span class="input-group-btn">-->
                    <!--                          <input type="file" ref="fileThree"  @change="addImage(2)" style="display: none;" />-->
                    <!--							          </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                  </div>
                  <div class="buttons">
                    <div class="pull-right">
                      <button type="button" class="btn btn-primary" id="button-confirm" @click="addProduct">상품 등록</button>
                    </div>
                  </div>

                </div>
              </div>









            </div>
          </div>

        </div>
      </div>


    </div>
    <!-- //Main Container -->



  </div>
</template>
<!--<script src="https://sdk.amazonaws.com/js/aws-sdk-2.283.1.min.js"></script>-->
<script>
import $ from "jquery";
// import s3Image  from '@/store/module/s3Image';
// import utils from "../utils/AprilUtils";
import { mapGetters } from "vuex";
// import axios from "axios";
//
// const _axios = axios.create();
// _axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// _axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
// _axios.defaults.headers.common["AAccess-Control-Allow-Methods"] = "*";
// _axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, X-Requested-With, Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Access-Control-Allow-Methods";



// import aprilUtils from "../utils/AprilUtils";
//
// const aws = require("aws-sdk");
//
// import { mapGetters } from "vuex";
//
//
// aws.config.update({
//   region: "ap-northeast-2",
//   credentials: new aws.CognitoIdentityCredentials({
//     IdentityPoolId: "ap-northeast-2:58dd1fe3-0e1b-4b18-8e10-18c4f9172bc5",
//   }),
// });
// const s3 = new aws.S3({
//   // apiVersion: '2006-03-01',
//   // params: {Bucket: albumBucketName}
// });




export default {
  name: 'ProductAddPage',
  data(){
    return{
      productsName : null,
      productsText : null,
      defaultPrice : null,
      stockAmount : null,
      discountPercent : null,
      discountPrice : null,
      publicYn : null,
      link : null,

      checkImgSize : 0,
      checkImgSeq : 0,

      discountType : 1,
      discountMoney : null,

      categoryList : null,
      categoryEditList : null,
      categoryCheck : [],
      mediaFiles : [],
      imageLists : null,

      firstImgPath : '',
      secImgPath : '',
      thirdImgPath : '',

      addMewImage : false,
      activeType : 'add',

      getLoadProductCode : '',
    }
  },
  watch : {

    checkImgSize: function(val){
      if(val > 0){

        this.saveImgToDispatch();

      }
    },
    // mediaFiles(newVal){
    //   console.log(newVal)
    //   console.log(newVal.length)
    //   if(newVal.length === 5){
    //     console.log("상품 저장")
    //     // this.addProduct(newVal);
    //   }
    // }
  },
  computed : {
    ...mapGetters([
      'getProductsListsInMain',
      'getJustUploadedImageName',
      'getImgUrlOne',
      'getImgUrlTwo',
      'getImgUrlThree',
      'getImgUrlFour',
      'getImgUrlFive'
    ]),

  },
  methods : {
    deleteMultiMedia(){
      this.addMewImage = true;

     var param = {
       productsCode : this.$route.params.productsCode
     }
      console.log(this.$route.params.productsCode + "ddddd");


       this.api.removeMultimedia(param)
        .then((res)=>{

          if (this.utils.preApiHandle(res)) {
            console.log("util : ", res);
          }
          alert("이미지가 삭제되었습니다.")

          this.imageLists = null;
          this.addMewImage = false;
          this.productDetail();


          //this.getReloadProductCode();
          // window.location.reload(true);

          //this.$router.go(0);

        })
    },

    activeFileForm(seq){

      console.log(seq)


      // this.$store.dispatch('emptyImageData');

      if(seq == 0) {

        this.$refs.fileOne.click()
      } else if( seq == 1){
        this.$refs.fileTwo.click()

      } else if(seq == 2){
        this.$refs.fileThree.click()

      } else if(seq == 3){
        this.$refs.fileFour.click()

      } else if(seq == 4){
        this.$refs.fileFive.click()

      }


    },
    saveImgToDispatch(){

      var seq = this.checkImgSeq

      console.log(seq)

      var fileees = this.$refs.fileOne.files[0];


      this.$store.dispatch('uploadImage', {file : fileees, seq : seq})
        .then((res)=>{
          console.log("갸ㅕㄹ과 ",res)
        })
    },

    addImage(seq){
      console.log(seq)



      //
      this.checkImgSeq = seq;
      this.checkImgSize = 0;



      if(this.addMewImage === false  && seq === 0){
        const FileElement = document.querySelector('#filesOne');

        //axios 에서 되는지 테스트..
        // this.uploadFunction(FileElement.files);

        this.$aprilUtils.uploadStart(this, FileElement.files);
      }
      if(this.addMewImage === false   && seq === 1){
        const  FileElement = document.querySelector('#filesTwo');
        if(FileElement.files[0] === undefined){
          alert("이미지를 등록해주세요.")
          return;
        }

        this.$aprilUtils.uploadStart(this, FileElement.files);
      }
      if(this.addMewImage === false && seq === 2){
        const   FileElement = document.querySelector('#filesThree');
        this.$aprilUtils.uploadStart(this, FileElement.files);
      }
      if(this.addMewImage === false  && seq === 3){
        const FileElement = document.querySelector('#filesFour');
        this.$aprilUtils.uploadStart(this, FileElement.files);
      }
      if(this.addMewImage === false && seq === 4){
        const FileElement = document.querySelector('#filesFive');

        if(FileElement != undefined && FileElement != null){
          this.$aprilUtils.uploadStart(this, FileElement.files);
        }

      }



    },
    uploadFunction(fileList){
      let formData = new FormData();
      // const fileInput = document.getElementById("files");

      if (fileList === undefined) {
        alert("이미지를 등록해주세요.")
        return;
      }

      for (let i = 0; i < fileList.length; i++) {
        formData.append("files", fileList[i]);
      }

      this.api.uploadImage(formData)
        .then(res =>{
          console.log(res)
        })
    },
    uploadComplete(value){
      console.log(value)
        this.mediaFiles.push(value[0]);
      console.log(this.mediaFiles.length)
      console.log(typeof this.mediaFiles)
      console.log(this.mediaFiles)

      // if(this.checkImgSeq < 5){
      //   this.addImage(this.checkImgSeq + 1);
      // }


    },
    addProductClick(){
      // if(this.productsName == null){
      //   alert("상품명은 필수 입력 사항압니다.")
      //   return;
      // }
      //
      // // if(this.link == null){
      // //   alert("링크는 필수 입력 사항압니다.")
      // //   return;
      // // }
      //
      // if(this.defaultPrice == null){
      //   alert("원가는 필수 입력 사항압니다.")
      //   return;
      // }
      //
      //
      // if(this.discountMoney == null){
      //   alert("금액 설정의 값을 입력해주세요.")
      //   return;
      // }
      //
      // if(this.discountType == 1){
      //   this.discountPrice = this.discountMoney
      // } else {
      //   this.discountPercent = this.discountMoney
      // }

      //이미지 검증
     // this.addImage(0);



    },
    async readFileSize(file){

      var check = 0;

      var img = new Image();
      var _url = window.URL || window.webkitURL;

      img.src = _url.createObjectURL(file);
      img.onload = function(){
        if(img.width < (270) || img.height < (270)){
          alert("사이즈 작음 !!! ")


          check = -1;
        } else {
          check = 1;
        }
      }

      if(check == -1){
        console.log("삭제 하자 ", this.$refs.fileOne.value)
        this.$refs.fileOne.value = null;
        console.log("삭제 하자 ", this.$refs.fileOne.value)
      }



      return check;



    },
    deleteImg(path){
      console.log("이미지 삭제::")

      path = path.replace("https://modupang-products.s3.ap-northeast-2.amazonaws.com/","");

      this.$store.dispatch("removeImage", { file  : path})


    },

    addProduct(){

      console.log(this.mediaFiles[1] + "!!!!!!!!!")
      console.log(this.mediaFiles.length + "안에 들어있는!!!!!!!!")

      if(this.productsName == null){
        alert("상품명은 필수 입력 사항압니다.")
        return;
      }

      // if(this.link == null){
      //   alert("링크는 필수 입력 사항압니다.")
      //   return;
      // }

      if(this.defaultPrice == null){
        alert("원가는 필수 입력 사항압니다.")
        return;
      }


      if(this.discountMoney == null){
        alert("금액 설정의 값을 입력해주세요.")
        return;
      }

      if(this.discountType == 1){
        this.discountPrice = this.discountMoney
      } else {
        this.discountPercent = this.discountMoney
      }




      if(this.$route.params.productsCode === undefined){
        if(this.mediaFiles.length < 5){
          alert("이미지를 5장 등록해주세요.")
          return;
        }
      }

      // if(this.$route.params.productsCode !== undefined){
      //   if(this.addMewImage !== true){
      //     alert("이미지를 5장 등록해주세요.")
      //     return;
      //   }
      // }


      // if(this.$route.params.productsCode !== undefined){
      //   if(this.mediaFiles.length < 5){
      //     alert("이미지를 5장 등록해주세요.")
      //     return;
      //   }
      // }


      console.log("dddddddddddd",this.mediaFiles)


      var code = null;
      if(this.$route.params.productsCode !== undefined){
        code = this.$route.params.productsCode
      }


      console.log(this.mediaFiles)


      var param = {
        productsCode : code,
        productsName : this.productsName,
        companyCode : this.$store.getters['loginStore/companyCode'].companyCode,
        productsText : this.productsText,
        defaultPrice : this.defaultPrice,
        stockAmount : this.stockAmount,
        sellPrice : this.sellPrice,
        discountPercent : this.discountPercent,
        discountPrice : this.discountPrice,
        publicYn : 'N',
        link : this.link,
        categoryCheck : this.categoryCheck.toString(),
        files : this.mediaFiles
      }

      console.log(param)

      this.api.productAdd(param)
        .then((res)=>{

        if (this.utils.preApiHandle(res)) {
          console.log("util : ", res);
        }

        alert("저장되었습니다.");

        this.$router.push({name: "ProductPage"})
      })

    },
    productDetail(){

      this.activeType = 'revise';

      var checkparam = {
        productCode : this.$route.params.productsCode
      }

      this.api.productEach(checkparam)
        .then((res)=>{
          console.log("수정 : ",res)

          if (this.utils.preApiHandle(res)) {
            console.log("util : ", res);
          }

          this.productsName = res.product.productsName
          this.productsText = res.product.productsText
          this.defaultPrice  = res.product.defaultPrice
          this.stockAmount = res.product.stockAmount


          if(res.product.discountPercent == "0"){

            this.discountType = 1
            this.discountMoney = res.product.discountPrice

          } else if(res.product.discountPrice == "0"){

            this.discountType = 2
            this.discountMoney = res.product.discountPercent
          }

          this.link = res.product.link

          if(res.product.categList != null){
            console.log(JSON.parse(res.product.categList).length)

            for(var i = 0; i < JSON.parse(res.product.categList).length; i++){
              this.categoryCheck.push(JSON.parse(res.product.categList)[i])

            }

          }

          if(res.product.images.length > 0){
            this.addMewImage = true;
            this.imageLists = res.product.images;
          }

        })
    },

    getReloadProductCode() {
      if(this.$route.params.productsCode != undefined){

        //this.productDetail();
        this.getLoadProductCode = true,
        console.log("111111111")
        //window.location.reload(true);
      }
    },

  },
  created() {
    console.log('ProductAddPage.created');
    console.log(this.getLoadProductCode + "+ 혹시!@!@!@!");

    console.log("수정?", this.$route.params.productsCode)
    this.activeType = 'add';
    console.log("혹시 여기로 들어오니???????ㄴ" + this.$route.params.productsCode)

    if(this.$route.params.productsCode == undefined){


      //this.productDetail();
      console.log("혹시 여기로 들어오니!!!!!!!!!!!!!")
    }


    if(this.$route.params.productsCode != undefined){


      this.productDetail();
console.log("혹시 여기로 들어오니???????ㄴ")
    }





    var param = {
      keyword : ""
    }

    this.api.category(param)
      .then((res)=>{
        console.log(res);

        if (this.utils.preApiHandle(res)) {
          console.log("util : ", res);
        }

        this.categoryList = res.category;

      })

    this.$store.dispatch('emptyImageData', {imageOne :  this.firstImgPath, imageTwo :  this.firstImgPath, imageThree : this.secImgPath, imageFour  : this.secImgPath, imageFive : this.thirdImgPath })

  },
  mounted() {
    console.log('ProductAddPage.mounted');

    $('html, body').animate({scrollTop:0},400);

  },
  beforeDestroy() {
    console.log('ProductAddPage.beforeDestroy');


  },


};
</script>
