<template>
  <div>
    <div class="main-container container">
      <div class="row">
        <div id="content" class="col-sm-12" style="margin: 3pc;">
          <h2 class="title">회원 가입</h2>
          <p>이미 회원이라면 로그인 창에서 <router-link to="/login">로그인</router-link> 해주세요.</p>
          <div class="form-horizontal account-register clearfix">


            <fieldset id="account">
              <legend>개인 정보</legend>

              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-firstname">이메일</label>
                <div class="col-sm-8">
                  <input type="text" name="이메일" value="" v-model="userEmail" placeholder="이메일" id="input-firstname" class="form-control">
                  <span v-if="userEmail != null && emailDuplicated < 0" style="color: red;">중복 또는 탈퇴 이메일 입니다.</span>
                  <span v-if="userEmail != null && emailDuplicated == 1" style="color:#319131;">사용가능한 이메일입니다.</span>
                </div>
                <div class="col-sm-2">
                  <button type="button" class="btn btn-primary" @click="emailCheck">이메일 중복 확인</button>
                </div>
              </div>
              <!--              <div class="form-group required">-->
              <!--                <label class="col-sm-2 control-label" for="input-lastname">이름 </label>-->
              <!--                <div class="col-sm-10">-->
              <!--                  <input type="text" name="lastname" value="" placeholder="이름" id="input-lastname" class="form-control">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="form-group required">-->
              <!--                <label class="col-sm-2 control-label" for="input-email">이메일</label>-->
              <!--                <div class="col-sm-10">-->
              <!--                  <input type="email" name="email" value="" placeholder="이메일" id="input-email" class="form-control">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="form-group required">-->
              <!--                <label class="col-sm-2 control-label" for="input-telephone">휴대전화</label>-->
              <!--                <div class="col-sm-10">-->
              <!--                  <input type="tel" name="telephone" value="" placeholder="휴대전화" id="input-telephone" class="form-control">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="form-group">-->
              <!--                <label class="col-sm-2 control-label" for="input-fax">팩스</label>-->
              <!--                <div class="col-sm-10">-->
              <!--                  <input type="text" name="fax" value="" placeholder="팩스" id="input-fax" class="form-control">-->
              <!--                </div>-->
              <!--              </div>-->
            </fieldset>
            <fieldset id="address">
              <legend>회사 정보</legend>
              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-company">회사명</label>
                <div class="col-sm-10">
                  <input type="text" name="company" value="" placeholder="회사명" id="input-company" v-model="companyName" class="form-control">
                </div>
              </div>
              <div class="form-group ">
                <label class="col-sm-2 control-label" for="input-address-1">기본주소</label>
                <div class="col-sm-10">
                  <input type="text" name="address_1" value="" placeholder="기본주소" id="input-address-1" v-model="companyAddress" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="input-address-2">회사전화번호</label>
                <div class="col-sm-10">
                  <input type="text" name="address_2" value="" placeholder="회사전화번호" id="input-address-2" v-model="companyPhone" class="form-control">
                </div>
              </div>

              <div class="form-group ">
                <label class="col-sm-2 control-label" for="input-postcode">팩스번호</label>
                <div class="col-sm-10">
                  <input type="text" name="postcode" value="" placeholder="팩스번호" id="input-postcode" v-model="companyFax" class="form-control">
                </div>
              </div>


            </fieldset>

            <fieldset>
              <legend>비밀번호</legend>
              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-password">비밀번호</label>
                <div class="col-sm-10">
                  <input type="password" name="password" value="" placeholder="비밀번호" id="input-password" v-model="userPw" class="form-control">
                  <span v-if="userPw != null && userPw.length < 8"  style="color: red;">비밀번호는 8자 이상 입력해주세요.</span>
                </div>
              </div>
              <div class="form-group required">
                <label class="col-sm-2 control-label" for="input-confirm">비밀번호 재입력</label>
                <div class="col-sm-10">
                  <input type="password" name="confirm" value="" placeholder="비밀번호 재입력" id="input-confirm" v-model="userPw1" class="form-control">
                  <span v-if="userPw1 != null && userPw != userPw1 "  style="color: red;">비밀번호가 일치하지 않습니다.</span>
                </div>
              </div>
            </fieldset>
          </div>
          <!--            <fieldset>-->
          <!--              <legend>SNS 수신</legend>-->
          <!--              <div class="form-group">-->
          <!--                <label class="col-sm-2 control-label">SNS 수신을 동의하십니까?</label>-->
          <!--                <div class="col-sm-10">-->
          <!--                  <label class="radio-inline">-->
          <!--                    <input type="radio" name="newsletter" value="1"> 동의-->
          <!--                  </label>-->
          <!--                  <label class="radio-inline">-->
          <!--                    <input type="radio" name="newsletter" value="0" checked="checked"> 비동의-->
          <!--                  </label>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </fieldset>-->
          <div class="buttons">
            <!--              <div class="pull-right">모두팡 이용약관 동의 (필수) <a href="#" class="agree"><b>모두 동의합니다.</b></a>-->
            <!--                <input class="box-checkbox" type="checkbox" name="agree" value="1"> &nbsp;-->
            <!--                <br>-->
            <button type="button" class="btn btn-primary" style="float:right;" @click="singInBtn">가입하기</button>

            <!--              </div>-->
          </div>

        </div>
      </div>
    </div>

    <!--    회원가입-->

    <!--    <form class="form mt-5">-->
    <!--      <div class="form-group mb-1">-->
    <!--        <div class="input-group">-->
    <!--          <input type="text" class="form-control" placeholder="이메일">-->
    <!--        </div>-->

    <!--      </div>-->
    <!--      <div class="form-group">-->
    <!--        <div class="input-group">-->
    <!--          <input type="password" class="form-control" placeholder="회사명">-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="form-group">-->
    <!--        <div class="input-group">-->
    <!--          <input type="password" class="form-control" placeholder="아이디">-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="form-group">-->
    <!--        <div class="input-group">-->
    <!--          <input type="password" class="form-control" placeholder="비밀번호">-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="form-group">-->
    <!--        <div class="input-group">-->
    <!--          <input type="password" class="form-control" placeholder="비밀번호 재확인">-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </form>-->
    <!--    <div class="footer mt-3">-->
    <!--      <button type="button" class="btn btn-secondary btn-block" @click="singInBtn">가입하기</button>-->
    <!--    </div>-->

  </div>
</template>

<script>
export default {
  name: 'SignInPage',
  data(){
    return{
      userEmail : null,
      userPw : null,
      userPw1 : null,
      companyName : null,
      companyPhone : null,
      companyFax : null,
      companyAddress : null,

      emailDuplicated : 0,


    }
  },
  methods:{
    emailCheck(){

      var param = {
        userEmail : this.userEmail
      }

      this.api.emailCheck(param)
        .then((res)=>{
          this.emailDuplicated = res.check

          if(this.emailDuplicated < 1){
            console.log("정상")
            this.emailDuplicated = 1;

          } else if(this.emailDuplicated > 0){
            alert("중복 또는 탈퇴한 이메일입니다.")
            this.emailDuplicated = -1;

          }

        })

    },
    singInBtn(){

      if(this.userEmail == null){
        alert("이메일은 필수입력 항목입니다.")
        return;
      }

      if(this.emailDuplicated == -1){
        alert("이메일 중복 확인을 해주세요.")
        return;
      }

      if(this.companyName == null){
        alert("회사명은 필수입력 항목입니다.")
        return;
      }

      if(this.userPw  == null){
        alert("비밀번호를 확인해주세요.")
        return;
      }

      if(this.userPw1  == null){
        alert("비밀번호를 확인해주세요.")
        return;
      }

      if(this.userPw != this.userPw1){
        alert("비밀번호를 확인해주세요.")
        return;
      }

      var param = {
        userEmail : this.userEmail,
        userPw : this.userPw,
        companyName : this.companyName,
        companyPhone : this.companyPhone,
        companyFax : this.companyFax,
        companyAddress : this.companyAddress
      }

      this.api.signIn(param)
        .then((res)=>{
          console.log(res)

          alert("회원가입에 성공했습니다.")
          this.$router.push({name: "LoginPage"})

        })


    }
  },

  created() {
    console.log('SignInPage.created');
  },
  mounted() {
    console.log('SignInPage.mounted');
  },
  beforeDestroy() {
    console.log('SignInPage.beforeDestroy');
  },
};
</script>
