import { productsStoreActions } from './productsStoreActions';
export const productsStore = {
  namespaced: true,
  state: {
    keyword: '', // 
    commonCode: '', // 
    commonName: '', // 
    productsList: '', // 
    totalCount: '', // 
    categoryList: '', // 카테고리 리스트~
  },
  getters: {
    keyword: state => {
      if (state.keyword === undefined || state.keyword === null || state.keyword === '') {
        const jsonStr = localStorage.getItem('keyword');
        if (jsonStr === undefined || jsonStr === null) {
          state.keyword = null;
          return state.keyword;
        } else {
          state.keyword = JSON.parse(jsonStr);
        }
      }
      return state.keyword;
    },
    commonCode: state => {
      if (state.commonCode === undefined || state.commonCode === null || state.commonCode === '') {
        const jsonStr = localStorage.getItem('commonCode');
        if (jsonStr === undefined || jsonStr === null) {
          state.commonCode = null;
          return state.commonCode;
        } else {
          state.commonCode = JSON.parse(jsonStr);
        }
      }
      return state.commonCode;
    },
    commonName: state => {
      if (state.commonName === undefined || state.commonName === null || state.commonName === '') {
        const jsonStr = localStorage.getItem('commonName');
        if (jsonStr === undefined || jsonStr === null) {
          state.commonName = null;
          return state.commonName;
        } else {
          state.commonName = JSON.parse(jsonStr);
        }
      }
      return state.commonName;
    },
    productsList: state => {
      if (state.productsList === undefined || state.productsList === null || state.productsList === '') {
        const jsonStr = localStorage.getItem('productsList');
        if (jsonStr === undefined || jsonStr === null) {
          state.productsList = null;
          return state.productsList;
        } else {
          state.productsList = JSON.parse(jsonStr);
        }
      }
      return state.productsList;
    },
    totalCount: state => {
      if (state.totalCount === undefined || state.totalCount === null || state.totalCount === '') {
        const jsonStr = localStorage.getItem('totalCount');
        if (jsonStr === undefined || jsonStr === null) {
          state.totalCount = null;
          return state.totalCount;
        } else {
          state.totalCount = JSON.parse(jsonStr);
        }
      }
      return state.totalCount;
    },
    categoryList: state => {
      if (state.categoryList === undefined || state.categoryList === null || state.categoryList === '') {
        const jsonStr = localStorage.getItem('categoryList');
        if (jsonStr === undefined || jsonStr === null) {
          state.categoryList = null;
          return state.categoryList;
        } else {
          state.categoryList = JSON.parse(jsonStr);
        }
      }
      return state.categoryList;
    },
  },
  mutations: {
    keyword: (state, keyword) => {
      if (keyword === undefined || keyword === null) {
        localStorage.removeItem('keyword');
      } else {
        localStorage.setItem('keyword', JSON.stringify(keyword));
      }
      state.keyword = keyword;
    },
    commonCode: (state, commonCode) => {
      if (commonCode === undefined || commonCode === null) {
        localStorage.removeItem('commonCode');
      } else {
        localStorage.setItem('commonCode', JSON.stringify(commonCode));
      }
      state.commonCode = commonCode;
    },
    commonName: (state, commonName) => {
      if (commonName === undefined || commonName === null) {
        localStorage.removeItem('commonName');
      } else {
        localStorage.setItem('commonName', JSON.stringify(commonName));
      }
      state.commonName = commonName;
    },
    productsList: (state, productsList) => {
      if (productsList === undefined || productsList === null) {
        localStorage.removeItem('productsList');
      } else {
        localStorage.setItem('productsList', JSON.stringify(productsList));
      }
      state.productsList = productsList;
    },
    totalCount: (state, totalCount) => {
      if (totalCount === undefined || totalCount === null) {
        localStorage.removeItem('totalCount');
      } else {
        localStorage.setItem('totalCount', JSON.stringify(totalCount));
      }
      state.totalCount = totalCount;
    },
    categoryList: (state, categoryList) => {
      if (categoryList === undefined || categoryList === null) {
        localStorage.removeItem('categoryList');
      } else {
        localStorage.setItem('categoryList', JSON.stringify(categoryList));
      }
      state.categoryList = categoryList;
    },
  },
  actions: productsStoreActions,
};
