<template>
  <div  class="main-container container" style="    margin-bottom: 5pc;    margin-top: 5pc;">
    <!--    <ul class="breadcrumb">-->
    <!--      <li><a href="#"><i class="fa fa-home"></i></a></li>-->
    <!--      <li><a href="#">계정</a></li>-->
    <!--      <li><a href="#">로그인</a></li>-->
    <!--    </ul>-->
    <div class="row">
      <div id="content" class="col-sm-12">
        <div class="page-login">

          <div class="account-border">
            <div class="row">
              <div class="col-sm-6 new-customer">
                <div class="well">
                  <h2><i class="fa fa-file-o" aria-hidden="true"></i> 회원가입</h2>
                  <p>회원가입을 하셔야 서비스 이용이 가능합니다.  </p>
                </div>
                <div class="bottom-form">
                  <button type="button" class="btn btn-default pull-right"  @click="signInBtn">회원가입</button>
                </div>
              </div>

              <div class="col-sm-6 customer-login">
                <div class="well">
                  <h2><i class="fa fa-file-text-o" aria-hidden="true"></i> 로그인</h2>
                  <p><strong>기존 회원이신가요?</strong></p>
                  <div class="form-group">
                    <label class="control-label " for="input-email">아이디</label>
                    <input type="text" name="email" value="" id="input-email" v-model="userEmail"  class="form-control" v-on:keyup.enter="loginBtn" />
                  </div>
                  <div class="form-group">
                    <label class="control-label " for="input-password">비밀번호</label>
                    <input type="password" name="password" value="" id="input-password"  v-model="userPw"  class="form-control"  v-on:keyup.enter="loginBtn" />
                  </div>
                </div>
                <div class="bottom-form">
                  <!--                    <a href="#" class="forgot">비밀번호 찾기</a>-->
                  <button type="button" value="로그인" class="btn btn-default pull-right"  @click="loginBtn" >로그인</button>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- //Main Container -->

  </div>
</template>


<script>
export default {
  name: 'LoginPage',
  data (){
    return{
      userEmail : null,
      userPw : null,

    }
  },
  methods : {
    loginBtn(){

      if(this.userEmail == null){
        alert("이메일을 입력해주세요.");
        return;
      }

      if(this.userPw == null){
        alert("비밀번호를 입력해주세요.");
        return;
      }


      var param = {
        userEmail : this.userEmail,
        userPw : this.userPw,
      }

      this.api.login(param)
        .then((res)=>{
          console.log(res);

          if(res.code == -1){
            alert("아이디나 비밀번호를 확인해주세요.")
            return;
          }

          this.$store.dispatch("loginStore/settingLogin", {companyCode : res.user.userCode, userEmail : res.user.userEmail, insertDt : res.user.insertDt, deleteYn : res.user.deleteYn});

          this.$router.push({name: "MainPage"})



        })

    },

    signInBtn(){
      console.log("이동")
      this.$router.push({name: "SignInPage"})
    }

  },
  created() {
    console.log('LoginPage.created');
    console.log(this.siteUrl)
  },
  mounted() {
    console.log('LoginPage.mounted');
  },
  beforeDestroy() {
    console.log('LoginPage.beforeDestroy');
  },
};
</script>


