<template>
    <div  v-if="$store.getters['loginStore/companyCode'].companyCode != null">
        <aside class="col-sm-3 hidden-xs" id="column-right">
            <h2 class="subtitle">계정 관리</h2>
            <div class="list-group">
                <ul class="list-item">
                    <li>{{this.$store.getters['loginStore/companyCode'].companyCode}}</li>
                    <li><button type="button" class="btn btn-action" style="background: transparent;" @click="showModal(0)">API키</button></li>
                    <li><button type="button" class="btn btn-action" style="background: transparent;" @click="showModal(1)">트래킹코드</button>
                    </li>
                    <li><button type="button" class="btn btn-action" style="background: transparent;" @click="toPlan">플랜</button>
                    </li>
                </ul>
            </div>
            <hr>
            <!--      <h2 class="subtitle">프로세스바</h2>-->
            <div class="list-group">
                <h4>
                    {{planName}}

                    <select @change="getUsingList($event.target.value)">
                        <option value="">날짜선택</option>
                        <option value="2024-05">2024-05</option>
                        <option value="2024-06">2024-06</option>
                        <option value="2024-07">2024-07</option>
                        <option value="2024-08">2024-08</option>
                        <option value="2024-09">2024-09</option>
                        <option value="2024-10">2024-10</option>
                        <option value="2024-11">2024-11</option>
                        <option value="2024-12">2024-12</option>
<!--                        <option value="2024-01">2024-01</option>-->
<!--                        <option value="2024-02">2024-02</option>-->
<!--                        <option value="2024-03">2024-03</option>-->
<!--                        <option value="2024-04">2024-04</option>-->
<!--                        <option value="2024-05">2024-05</option>-->
<!--                        <option value="2024-06">2024-06</option>-->
<!--                        <option value="2024-07">2024-07</option>-->
<!--                        <option value="2024-08">2024-08</option>-->
<!--                        <option value="2024-09">2024-09</option>-->
                    </select>
                </h4>
                <ul class="list-item">
                    <li>
<!--                        <label>사용 건수 : {{totalCount}}건 / 사용가능 건수 : <span v-if="limitCount !== -1 && limitCount - totalCount >= 0">{{ limitCount - totalCount}}건</span><span v-if="limitCount === -1">무제한</span><span v-if="limitCount !== -1 && limitCount - totalCount < 0">0건</span></label>-->
                        <label>상품등록 : {{totalCount}} / 5</label>
<!--                        <div class="progress">-->
<!--                            <div class="progress-bar" id="countCount" role="progressbar" :style="firstProcess" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"> <span v-if="firstProcess.background== '#f4b137'">무제한</span> <span v-if="firstProcess.background != '#f4b137'"></span> </div>-->
<!--                        </div>-->
<!--                        <span v-if="planBar">기간 만료</span>-->
                        <br />
                        <label>사용량 : {{useCount}} / 3,000</label>
                        <br />
                        <label>기간 : {{useDate}}</label>
                        <br />
                        <label>계약방법 : 계약</label>
                    </li>
                    <!--          <li>-->
                    <!--            <label>ff</label>-->
                    <!--            <div class="progress">-->
                    <!--              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->
                    <!--            </div>-->
                    <!--          </li>-->
                    <!--          <li>-->
                    <!--            <label>ff</label>-->
                    <!--            <div class="progress">-->
                    <!--              <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->
                    <!--            </div>-->
                    <!--          </li>-->
                </ul>
            </div>
        </aside>
        <user-modal v-bind:apiKey="apiKey"  v-bind:trackingCode="trackingCode"/>
    </div>

</template>

<script>
import { mapGetters } from "vuex";

import UserModal from "@/components/common/UserModal";

export default {
    name: "SideMenu",
    components : {
        UserModal
    },
    computed : {
        ...mapGetters([
            'getCompanyData'
        ]),
    },
    data(){
        return{
            planName : '플랜',
            totalCount : 0,
            limitCount : 10,
            totalAd : 0,
            serviceLogCount : 0,
            apiKey : 10,
            trackingCode : 10,
            planBar : false,

            firstProcess : {
                // width : '0%',
                background : '#337ab7'
            },
            usingList : null,
            useDate : "",
            useCount : 0,
        }
    },
    methods : {
        toPlan(){
            this.$router.push({name: "PlanPage"})
        },
        showModal(val){
            console.log(this.$store.getters['userModalStore/visible'].visible )
            var check = false;
            if(this.$store.getters['userModalStore/visible'].visible != null || this.$store.getters['userModalStore/visible'].visible === false){
                check = true;
            }
            this.$store.dispatch("userModalStore/setUserModal", {status : check,num : val})

        },
        getUsingList(date) {
            let obj = new Object();
            obj.companyCode = this.$store.getters['loginStore/companyCode'].companyCode
            obj.dateOfSearch = date;

            console.log(obj);

            this.api.getUsingList(obj)

                .then((res) => {
                    console.log(res);
                    this.usingList = res.usingList;
                    // this.useDate = task.frontDate + " ~ " + data.body.task.endDate
                    // useDate

                    this.totalCount = res.task.productsCount;
                    this.useCount = res.task.serviceLogCount * 1 + res.task.productsCount;
                });
        },
    },
    created() {
        console.log(this.$store.getters['userModalStore/visible'])

        this.$store.dispatch("userModalStore/setUserModal", {status : false, num : 0})

        //task 데이터 가지고 오기~

        var param = {
            companyCode : this.$store.getters['loginStore/companyCode'].companyCode
        }

        this.api.getUserTask(param)
            .then((res)=>{
                console.log("결과:",res)

                this.planName = res.task.planName;
                this.apiKey = res.task.apiKey;
                this.trackingCode = res.task.trackingCode;
                // this.totalCount = res.task.totalCount;
                this.totalAd = res.task.totalAd;


                this.useDate = res.task.startDt + " ~ " + res.task.endDt;

                if(res.task.limitCount < 0){
                    this.limitCount = -1;
                    this.firstProcess.background = '#f4b137';
                    // this.firstProcess.width = '100%';
                    document.getElementById("countCount").style.width = "100%";
                } else {
                    this.limitCount = res.task.limitCount
                    this.firstProcess.background = '#337ab7';
                    // this.firstProcess.width = res.task.totalCount / res.task.limitCount;

                    var percent = 0;
                    if(res.task.totalCount > 0){
                        percent = res.task.totalCount / res.task.limitCount * 100;

                    }

                    if(res.task.totalCount > 0 && percent <= 0){
                        document.getElementById("countCount").style.width = "100%";
                        document.getElementById("countCount").style.background = "#af0505";

                    } else if(res.task.totalCount > 0 && percent == Infinity){
                        document.getElementById("countCount").style.width = "100%";
                        document.getElementById("countCount").style.background = "#af0505";
                    } else {
                        document.getElementById("countCount").style.width = percent + "%";

                    }



                    if(res.task.endDt != null || res.task.endDt != ""){
                        var current = new Date();
                        var nowDate = new Date(current.getFullYear(), current.getMonth(), current.getDate())

                        var daysplit = res.task.endDt.split('-');

                        var endDt = new Date(daysplit[0], daysplit[1]-1, daysplit[2].substr(0,2));

                        console.log(endDt > nowDate)
                        console.log(endDt != nowDate)
                        console.log(endDt.getTime() )
                        console.log(nowDate.getTime() )

                        if(endDt < nowDate ){
                            document.getElementById("countCount").style.width = "100%";
                            document.getElementById("countCount").style.background = "#af0505";
                            this.planBar = true;
                        }
                    }


                }
            });
    }
};
</script>
