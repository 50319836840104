<template>
  <div class="module layout2-listingtab2">

    <div id="so_listing_tabs_2" class="so-listing-tabs first-load">
      <div class="ltabs-wrap">
          <div class="module listingtab-layout2">
            <h3 class="modtitle"><span>전체 이벤트</span>
            <button class="btn btn-outline-info" @click="toAllCategory" style="background: transparent;">이벤트 더보기</button>
            </h3>

          </div>
<!--        <div class="ltabs-tabs-container" data-rtl="yes" data-delay="300" data-duration="600" data-effect="starwars" data-ajaxurl="" data-type_source="0" data-lg="5" data-md="4" data-sm="2" data-xs="1" data-margin="30">-->
<!--          &lt;!&ndash;Begin Tabs&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="ltabs-tabs-wrap">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class='ltabs-tab-selected'>New Arrivals</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="ltabs-tab-arrow">▼</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <ul class="ltabs-tabs cf list-sub-cat font-title">&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="ltabs-tab tab-sel" data-category-id="61" data-active-content=".items-category-61"><span class="ltabs-tab-label">Best sellers</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="ltabs-tab  " data-category-id="62" data-active-content=".items-category-62">   <span class="ltabs-tab-label">New Arrivals</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="ltabs-tab  " data-category-id="63" data-active-content=".items-category-63">   <span class="ltabs-tab-label">Most Rating</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;            </ul>&ndash;&gt;-->
<!--          &lt;!&ndash; End Tabs&ndash;&gt;-->
<!--        </div>-->
        <div class="wap-listing-tabs ltabs-items-container products-list grid" style="margin-top: 0; width: 1700px;">
          <!--Begin Items-->
          <div class="ltabs-items-inner ltabs-slider" data-total="10">

            <div class="ltabs-items-inner ltabs-slider">

              <div class="ltabs-item" style="width: 320px; float: left; margin-right: 20px;" v-for="(each, index) in totalList" :key="index">
                <div class="item-inner product-layout transition product-grid">
                  <div class="product-item-container">
                    <div class="left-block">
                      <div class="product-image-container second_img" v-if="each.link != null && each.link != ''">
                        <a :href="each.link" target="_blank" >
                          <img :src="imagePath(each.images,each.imgList)" class="img-1 img-responsive" style="width: 320px; height: 320px;">
<!--                          <img :src="imagePath(each.imgList, 3)" class="img-2 img-responsive" style="width: 320px; height: 320px;">-->
                        </a>
<!--                        <div class="box-label" v-if="newLabel(each.insertDt) < 8"> <span class="label-product label-new"> 신규 </span></div>-->
                      </div>
                      <div class="product-image-container second_img" v-if="each.link == null || each.link == ''">
                        <div @click="toProductDetailPage(each.productsCode)">

                          <img :src="imagePath(each.images,each.imgList)" class="img-1 img-responsive" style="width: 320px; height: 320px;">
                          <img :src="imagePath(each.images,each.imgList)" class="img-2 img-responsive" style="width: 320px; height: 320px;">
                        </div>
                      </div>
<!--                      <div class="box-label" v-if="newLabel(each.insertDt) < 8"> <span class="label-product label-new"> 신규 </span></div>-->
<!--/*                      <div class="box-label"> <span class="label-product label-sale" style="width: auto; min-width: 40px;"><span v-if="each.discountPrice != 0"><i class="fa fa-won"></i> {{ priceConverting(each.discountPrice) }}</span><span v-if="each.discountPercent != 0">{{ priceConverting(each.discountPercent) }} <i class="fa fa-percent"  ></i></span></span></div>*/-->

<!--                      <div class="button-group so-quickview cartinfo&#45;&#45;left">-->
<!--                        <button type="button" class="addToCart btn-button" title="Add to cart" onclick="cart.add('60 ');">  <i class="fa fa-shopping-basket"></i>-->
<!--                          <span>Add to cart </span>-->
<!--                        </button>-->
<!--                        <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
<!--                        </button>-->
<!--                        <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
<!--                        </button>-->
<!--                        &lt;!&ndash;quickview&ndash;&gt;-->
<!--                        <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="quickview.html" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
<!--                        &lt;!&ndash;end quickview&ndash;&gt;-->
<!--                      </div>-->
                    </div>
                    <div class="right-block">
                      <div class="caption">

<!--                        <div class="rating">    <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
<!--                          <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
<!--                        </div>-->
                        <h4 v-if="each.link != null && each.link != ''">
                          <a :href="each.link" target="_blank">{{ cutTitle(each.productsName) }}</a>
                        </h4>
                        <h4 v-if="each.link == null || each.link == ''">
                          <a href="javascript:void(0)" @click="toProductDetailPage(each.productsCode)">{{ cutTitle(each.productsName) }}</a>
                        </h4>
<!--                        <div class="price"><i class="fa fa-won"></i> {{ priceConverting(each.sellPrice) }}</div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>


          </div>



          <!--End Items-->
        </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "MainNewPage",
  data(){
    return{
      totalList : null,
    }
  },
  methods : {
    cutTitle(title){

      if(title.length > 24){
        return title.substr(0, 22) + "..";
      } else {
        return title;
      }

    },
    toAllCategory(){
      this.$store.state.paginate = { pageNum : 15, skipNum : this.skipNum, totalCount : 0 }

      var param = {
        keyword : "",
        commonCode : "",
        pageNum : '15',
        skipNum : '0',

      }

      this.api.products(param)
        .then((res)=>{
          console.log(res)

          var data = {keyword : '', commonCode : '', commonName : '모든 카테고리', productsList : res.product};


          this.$store.dispatch("settingProductsListInMain", {products : data, totalCount : res.totalCount})

          this.$router.push({name: "MainProductsPage"})

        })
    },
    toProductDetailPage(value){
      this.$router.push({name: "MainProductsDetailPage", params : {"productsCode" : value}})
    },
    newLabel(value){

      var insertDt = new Date(value);
      var today = new Date();

      var diff = Math.abs(today.getTime() - insertDt.getTime());
      diff = Math.abs(diff / (1000 * 3600 * 24));

      return diff;

    },
    imagePath(value, list){
      console.log("main most page value", value);


      if(value != undefined && value.length > 2){
        return this.siteUrl + "/common/showImage?code=" + value[0].code + "&seq=3";
      }
      if(list != undefined && list != null){
        return "http://pang.frients.ai/modupang/" + list;


      } else {
        return "/assets/images/catalog/demo/product/index2/8.jpg";
      }



    },
    priceConverting(value){
      if(value > 0){
        return value.toLocaleString('ko-KR');

      } else {
        return 0;
      }
    },
  },
  created() {
    var param = {
      commonCode : "",
      keyword : "",
      pageNum : 10,
      skipNum : 0
    }

    this.api.products(param)
    .then((res)=>{
      console.log("totalList",res);

      this.totalList = res.product;
    })
  }
};
</script>

<style scoped>

</style>