<template>
  <div>
    <!--    {{getProductsListsInMain}}-->


    <!-- Main Container  -->
    <div class="main-container container">
      <ul class="breadcrumb">
        <li><a href="#"><i class="fa fa-home"></i></a></li>
        <li><button type="button" class="btn btn-default" style="border:none;">{{ $store.getters['productsStore/commonName'].commonName }}</button></li>
      </ul>

      <div class="row">

        <!--Middle Part Start-->
        <div id="content" class="col-md-12 col-sm-12">

          <div class="products-category">
            <div class="category-derc form-group">
              <h3 class="title-category">{{$store.getters['productsStore/commonName'].commonName }}</h3>
              <!-- <div class="banners">
                              <div>
                                  <a  href="#"><img src="image/catalog/demo/category/img-cate.jpg" alt="img cate"><br></a>
                              </div>
                          </div> -->
              <div class="category-desc">
                <p>다양한 해택을 가져가세요! </p>


              </div>

            </div>

            <!--changed listings-->
            <div class="products-list row nopadding-xs so-filter-gird grid" style="height: 750px;">

              <div class="product-layout col-lg-15 col-md-4 col-sm-6 col-xs-12" v-for="(list, index) in $store.getters['productsStore/productsList'].productsList" :key="index">
                <div class="product-item-container">
                  <div class="left-block">
                    <!--                    {{list.productsCode}}-->
                    <!--                   ------ 링크 있을때 -->
                    <div class="product-image-container second_img" v-if="list.link != null && list.link.indexOf('http') > -1">
                      <a  :href="list.link" target="_blank"  :title="list.productsName">
                        <img  :src="imagePath(list.images, list.imgList)" class="img-1 img-responsive" alt="image" style="width: 320px; height: 320px;">
                        <img :src="imagePath(list.images, list.imgList)"  class="img-2 img-responsive" alt="image" style="width: 320px; height: 320px;">
                      </a>
                      <!--                      <div class="box-label" v-if="checkNewOrNot(list.insertDt)"> <span class="label-product label-new"> 신규 </span></div>-->
                    </div>
                    <!--                 ------   링크 없을 때-->
                    <div class="product-image-container second_img" v-if="list.link == null || list.link.indexOf('http') === -1">
                      <div @click="productsLink(list.productsCode)"  >
                        <img :src="imagePath(list.images, list.imgList)" class="img-1 img-responsive" alt="image" style="width: 320px; height: 320px;">
                        <img  :src="imagePath(list.images, list.imgList)"  class="img-2 img-responsive" alt="image" style="width: 320px; height: 320px;">

                      </div>
                      <!--                      <div class="box-label"  v-if="checkNewOrNot(list.insertDt)"> <span class="label-product label-new"> 신규 </span></div>-->
                    </div>
                    <!--                    <div class="box-label" v-if="list.discountPercent != 0"> <span class="label-product label-sale"> {{list.discountPercent}}% </span></div>-->
                    <!--                    {{list.discountPrice}} {{list.discountPercent}}-->
                    <!--                    <div class="box-label"> - <span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="list.discountPrice != '0'" > <i class="fa fa-won" ></i> {{list.discountPrice}} </span><span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="list.discountPercent != '0'" > {{list.discountPercent}}<i class="fa fa-percent" ></i></span></div>-->

                    <!--                    <div class="button-group so-quickview cartinfo&#45;&#45;left">-->
                    <!--                      <button type="button" class="addToCart btn-button" title="Add to cart" onclick="cart.add('60 ');">  <i class="fa fa-shopping-basket"></i>-->
                    <!--                        <span>Add to cart </span>-->
                    <!--                      </button>-->
                    <!--                      <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
                    <!--                      </button>-->
                    <!--                      <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
                    <!--                      </button>-->
                    <!--                      &lt;!&ndash;quickview&ndash;&gt;-->
                    <!--                      <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0);" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
                    <!--                      &lt;!&ndash;end quickview&ndash;&gt;-->
                    <!--                    </div>-->
                  </div>
                  <div class="right-block">
                    <div class="caption">
                      <!--                      <div class="rating">    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                      </div>-->
                      <h4 v-if="list.link != null && list.link.indexOf('http') > -1">
                        <a :href="list.link" :title="list.productsName" target="_blank">{{ cutTitle(list.productsName) }}</a>
                      </h4>
                      <h4 v-if="list.link == null || list.link.indexOf('http') ===  -1">
                        <div href="javascript:void(0);" :title="list.productsName" @click="productsLink(list.productsCode)">{{ cutTitle(list.productsName)}}</div>
                      </h4>
<!--                      <div class="price"> <span class="price-new"><i class="fa fa-won"></i>{{ setDiscountedPrice(list.sellPrice) }}</span>-->
<!--                        <span class="price-old"><i class="fa fa-won"></i>{{ numberComma(list.defaultPrice) }}</span>-->
<!--                      </div>-->
                      <!--                      <div class="description item-desc">-->
                      <!--                        <p>{{list.productsText}}</p>-->
                      <!--                      </div>-->
                      <!--                      <div class="list-block">-->
                      <!--                        <button class="addToCart btn-button" type="button" title="Add to Cart" onclick="cart.add('101', '1');"><i class="fa fa-shopping-basket"></i>-->
                      <!--                        </button>-->
                      <!--                        <button class="wishlist btn-button" type="button" title="Add to Wish List" onclick="wishlist.add('101');"><i class="fa fa-heart"></i>-->
                      <!--                        </button>-->
                      <!--                        <button class="compare btn-button" type="button" title="Compare this Product" onclick="compare.add('101');"><i class="fa fa-refresh"></i>-->
                      <!--                        </button>-->
                      <!--                        &lt;!&ndash;quickview&ndash;&gt;-->
                      <!--                        <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0);" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i></a>-->
                      <!--                        &lt;!&ndash;end quickview&ndash;&gt;-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>

              <div class="product-layout col-lg-15 col-md-4 col-sm-6 col-xs-12" v-if="$store.getters['productsStore/productsList'].productsList.length == 0" >
                <div class="product-item-container">
                  <div class="left-block">
                    <!--                    {{list.productsCode}}-->
                    <!--                   ------ 링크 있을때 -->
                    <!--                 ------   링크 없을 때-->
                    <div class="product-image-container second_img" >
                      <div   >
                        <img src="../assets/image/favicon.png" class="img-1 img-responsive" alt="image" style="width: 320px; height: 320px;">
                        <img src="../assets/image/favicon.png"  class="img-2 img-responsive" alt="image" style="width: 320px; height: 320px;">

                      </div>
                      <!--                      <div class="box-label"  v-if="checkNewOrNot(list.insertDt)"> <span class="label-product label-new"> 신규 </span></div>-->
                    </div>
                    <!--                    <div class="box-label" v-if="list.discountPercent != 0"> <span class="label-product label-sale"> {{list.discountPercent}}% </span></div>-->
                    <!--                    {{list.discountPrice}} {{list.discountPercent}}-->
                    <!--                    <div class="box-label"> - <span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="list.discountPrice != '0'" > <i class="fa fa-won" ></i> {{list.discountPrice}} </span><span class="label-product label-sale" style="width: auto; min-width: 40px;" v-if="list.discountPercent != '0'" > {{list.discountPercent}}<i class="fa fa-percent" ></i></span></div>-->

                    <!--                    <div class="button-group so-quickview cartinfo&#45;&#45;left">-->
                    <!--                      <button type="button" class="addToCart btn-button" title="Add to cart" onclick="cart.add('60 ');">  <i class="fa fa-shopping-basket"></i>-->
                    <!--                        <span>Add to cart </span>-->
                    <!--                      </button>-->
                    <!--                      <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');"><i class="fa fa-heart"></i><span>Add to Wish List</span>-->
                    <!--                      </button>-->
                    <!--                      <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');"><i class="fa fa-refresh"></i><span>Compare this Product</span>-->
                    <!--                      </button>-->
                    <!--                      &lt;!&ndash;quickview&ndash;&gt;-->
                    <!--                      <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0);" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i><span>Quick view</span></a>-->
                    <!--                      &lt;!&ndash;end quickview&ndash;&gt;-->
                    <!--                    </div>-->
                  </div>
                  <div class="right-block">
                    <div class="caption">
                      <!--                      <div class="rating">    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                        <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>-->
                      <!--                      </div>-->
                      <h4><a href="javascript:void(0)" title="Pastrami bacon" target="_self">조금만 기다려주세요! 곧 새로운 이벤트가 등록됩니다.</a></h4>
                      <!--                      <div class="price"> <span class="price-new"><i class="fa fa-won"></i>{{ setDiscountedPrice(list.sellPrice) }}</span>-->
                      <!--                        <span class="price-old"><i class="fa fa-won"></i>{{ numberComma(list.defaultPrice) }}</span>-->
                      <!--                      </div>-->
                      <!--                      <div class="description item-desc">-->
                      <!--                        <p>{{list.productsText}}</p>-->
                      <!--                      </div>-->
                      <!--                      <div class="list-block">-->
                      <!--                        <button class="addToCart btn-button" type="button" title="Add to Cart" onclick="cart.add('101', '1');"><i class="fa fa-shopping-basket"></i>-->
                      <!--                        </button>-->
                      <!--                        <button class="wishlist btn-button" type="button" title="Add to Wish List" onclick="wishlist.add('101');"><i class="fa fa-heart"></i>-->
                      <!--                        </button>-->
                      <!--                        <button class="compare btn-button" type="button" title="Compare this Product" onclick="compare.add('101');"><i class="fa fa-refresh"></i>-->
                      <!--                        </button>-->
                      <!--                        &lt;!&ndash;quickview&ndash;&gt;-->
                      <!--                        <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="javascript:void(0);" title="Quick view" data-fancybox-type="iframe"><i class="fa fa-eye"></i></a>-->
                      <!--                        &lt;!&ndash;end quickview&ndash;&gt;-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>



            </div>

            <!--// End Changed listings-->

            <!-- Filters -->
            <div class="product-filter product-filter-bottom filters-panel">
              <div class="row">
                <div class="col-sm-6 text-left"></div>
                <div class="col-sm-6 text-right">

                  <div >
                    <button type="button" class="btn btn-action " v-if="getSkipNum !== 0 && getSkipNum > 0" @click="getPreviousPage">이전</button>
                    <span v-for="num in Math.ceil(getTotalCount / pageNum)" :key="num">

                      <button class="btn" v-if="num != getSkipNum+1 && num <= getSkipNum + 4 && num > getSkipNum - 4" style="color: #914100" @click="skipToPage(num)">{{ num }}</button>
                      <button type="button" class="btn" v-if="num == getSkipNum+ 1 " style="color: #ffa600" @click="skipToPage(num)">{{ num }}</button>
                    </span>
                    <!--{{Math.floor(this.totalCount/this.pageNum)}}-->
                    <button type="button" class="btn btn-action " v-if=" (pageNum * getSkipNum + pageNum) < getTotalCount" @click="getNextPage">다음</button>

                  </div>
                </div>
              </div>
            </div>
            <!-- //end Filters -->

          </div>
        </div>

      </div>
      <!--Middle Part End-->
    </div>
    <!-- //Main Container -->



  </div>
</template>
<!--<script type="text/javascript">-->
<!--// Check if Cookie exists-->
<!--if($.cookie('display')){-->
<!--  view = $.cookie('display');-->
<!--}else{-->
<!--  view = 'list';-->
<!--}-->
<!--if(view) display(view);-->
<!--//&ndash;&gt;</script>-->
<script>
import { mapGetters } from "vuex";
import eventbus from "@/store/module/eventbus";
import $ from "jquery";


export default {
  name: 'MainProductsPage',
  components : {
  },
  computed : {
    ...mapGetters([
       'productsStore/keyword',
      'productsStore/commonCode',
      'productsStore/commonName',
      'productsStore/totalCount',
      'productsStore/productsList',
      'paginateStore/pageNum',
      'paginateStore/skipNum',
      'paginateStore/totalCount',

    ]),
    // getTotal(){
    //   console.log("Fdsfds:" , this.$store.getters["paginateStore/pageNum"])
    //
    //   var data = {  pageNum : this.$store.getters['paginateStore/pageNum'].pageNum, skipNum : this.$store.getters['paginateStore/skipNum'].skipNum, totalCount  : this.$store.getters['paginateStore/totalCount'].totalCount}
    //
    //   console.log("fddaaata:",data )
    //
    //
    //   return data
    // },
    getTotalCount(){
      return this.$store.getters['productsStore/totalCount'].totalCount
    },
    getSkipNum(){
      return this.$store.getters['paginateStore/skipNum'].skipNum
    }

  },
  data(){
    return{
      pageNum : 15,
      skipNum : this.$store.getters['paginateStore/skipNum'].skipNum,
      // totalCount : this.$store.getters['productsStore/totalCount'].totalCount,
    }
  },
  methods : {
    cutTitle(title) {

      if (title.length > 24) {
        return title.substr(0, 22) + "..";
      } else {
        return title;
      }

    },
    getNextPage() {

      $('html, body').animate({ scrollTop: 0 }, 400);

      var skip = this.skipNum + 1;
      this.skipNum = skip;

      this.$store.dispatch("paginateStore/setPaginating", {
        pageNum: this.pageNum,
        skipNum: skip,
        totalCount: this.$store.getters['productsStore/totalCount'].totalCount
      })


      // this.$store.state.paginate.skipNum = this.skipNum;
      // this.$emit("activePage")

      this.setNewPage(skip);

    },
    skipToPage(num) {

      $('html, body').animate({ scrollTop: 0 }, 400);

      var skip = num - 1;
      this.skipNum = skip;


      console.log("ㅇㄹㅇㄴㄹㅇ", this.skipping)

      this.$store.dispatch("paginateStore/setPaginating", {
        pageNum: this.pageNum,
        skipNum: skip,
        totalCount: this.$store.getters['productsStore/totalCount'].totalCount
      })
      // this.$store.state.paginate = { pageNum : 15, skipNum : this.skipNum, totalCount : this.totalCount }
      // this.$emit("activePage")
      this.setNewPage(skip);

    },
    getPreviousPage() {

      $('html, body').animate({ scrollTop: 0 }, 400);

      var skip = this.skipNum - 1;
      this.skipNum = skip;
      this.$store.dispatch("paginateStore/setPaginating", {
        pageNum: this.pageNum,
        skipNum: skip,
        totalCount: this.$store.getters['productsStore/totalCount'].totalCount
      })
      // this.$store.state.paginate = { pageNum : 15, skipNum : skip, totalCount : this.totalCount }
      // this.$emit("activePage")
      this.setNewPage(skip);
    },


    checkNewOrNot(value) {
      var insertDt = new Date(value);
      var today = new Date();
      today = today.setDate(today.getDate() - 10);

      // console.log(insertDt > today)
      // console.log(today)


      return insertDt > today;
    },
    setNewPage(skipNum) {

      $('html, body').animate({ scrollTop: 0 }, 400);

      console.log("페이지 클릭", skipNum)

      this.skipNum = skipNum;

      //headerView로 값 올리기
      eventbus.$emit('changingPage', this.skipNum)


    },
    numberComma(value) {

      if (value >= 0) {
        return value.toLocaleString("ko-KR");

      } else {
        return "0";
      }
    },
    productsLink(value) {
      //링크가 없는 경우 detail 페이지로 이동
      console.log(value)


      this.$router.push({ name: "MainProductsDetailPage", params: { "productsCode": value } })

    },
    setDiscountedPrice(price) {


      if (price < 0) {
        price = 0;
      }

      return price.toLocaleString("ko-KR");

    },
    toProductDetail(code) {
      console.log(code)
    },
    //   imagePaths(value, num) {
    //
    //     var text = '';
    //
    //     if(value != null){
    //       text = value.split(",")
    //
    //     }
    //
    //     // console.log(text ,"////")
    //     // console.log(text.length ,"////")
    //
    //     if (text.length > num) {
    //       return (
    //         "https://modupang-products.s3.ap-northeast-2.amazonaws.com/" +
    //         text[num]
    //       );
    //     } else {
    //       return "https://modupang-products.s3.ap-northeast-2.amazonaws.com/common/320.jpg";
    //     }
    //   },
    // },
    imagePath(value, list) {

      if(value != null && value != undefined && value.length > 2){
        console.log("여기 들어옴", value[0])
        return this.siteUrl + "/common/showImage?code=" + value[0].code + "&seq=1";

      } else {
        return "http://pang.frients.ai/modupang/" + list;

      }
    },
  },
  created() {
    console.log('MainProductsPage.created');
    console.log(this.$store.getters['productsStore/productsList']);
  },
  mounted() {
    console.log('MainProductsPage.mounted');
  },
  beforeDestroy() {
    console.log('MainProductsPage.beforeDestroy');
  },
};
</script>
<style>
.pagingLi{
  float: left;
  margin-right: 15px;
}
</style>

<!--<style>-->



<!--@import "../../assets/css/footer/footer1.css";-->
<!--@import "../../assets/css/header/header1.css";-->

<!--</style>-->
