import Vue from "vue";
import Vuex from 'vuex';
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import AprilUtils from "@/utils/AprilUtils";
import store from "./store";
import "./service/apiservice";
import "./service/utilHandler";
// import "./plugins/apiservice";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library  } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)


library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
Vue.use(Vuex)
Vue.use(AprilUtils)

// Vue.prototype.siteUrl = "http://localhost:8081";
Vue.prototype.siteUrl = "http://49.247.13.66:8081";
// Vue.prototype.$siteUrl = "
// Vue.prototype.$siteUrl = "http://pang.frients.ai";

// Vue.api.useMock = true;


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
