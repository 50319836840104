export const userModalStoreActions = {
  setUserModal : function({commit}, {status, num}){

    commit("visible", {visible : status});
    commit("count", {count : num});

  },


}
