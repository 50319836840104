export const paginateStoreActions = {
  setPaginating({commit}, {pageNum, skipNum, totalCount}){
    
    console.log("페이징 저장")
    // var pg = { pageNum : pageNum, skipNum : skipNum, totalCount : totalCount  }

    commit("pageNum", {pageNum : pageNum})
    commit("skipNum", {skipNum : skipNum})
    commit("totalCount", {totalCount : totalCount})
  },
  setTotalCount({commit}, { totalCount}){

    console.log("페이징 저장")
    // var pg = { pageNum : pageNum, skipNum : skipNum, totalCount : totalCount  }

    commit("totalCount", {totalCount : totalCount})
  },
  setSkipNumber({commit}, { skipNum}){
    commit("skipNum", {skipNum : skipNum})
  }




}
