import { loginStoreActions } from './loginStoreActions';
export const loginStore = {
  namespaced: true,
  state: {
    accessToken: '', // access token
    companyCode: '', // login data
    userEmail: '', // login data
    insertDt: '', // login data
    deleteYn: '', // login data
  },
  getters: {
    accessToken: state => {
      if (state.accessToken === undefined || state.accessToken === null || state.accessToken === '') {
        const jsonStr = localStorage.getItem('accessToken');
        if (jsonStr === undefined || jsonStr === null) {
          state.accessToken = null;
          return state.accessToken;
        } else {
          state.accessToken = JSON.parse(jsonStr);
        }
      }
      return state.accessToken;
    },
    companyCode: state => {
      if (state.companyCode === undefined || state.companyCode === null || state.companyCode === '') {
        const jsonStr = localStorage.getItem('companyCode');
        if (jsonStr === undefined || jsonStr === null) {
          state.companyCode = null;
          return state.companyCode;
        } else {
          state.companyCode = JSON.parse(jsonStr);
        }
      }
      return state.companyCode;
    },
    userEmail: state => {
      if (state.userEmail === undefined || state.userEmail === null || state.userEmail === '') {
        const jsonStr = localStorage.getItem('userEmail');
        if (jsonStr === undefined || jsonStr === null) {
          state.userEmail = null;
          return state.userEmail;
        } else {
          state.userEmail = JSON.parse(jsonStr);
        }
      }
      return state.userEmail;
    },
    insertDt: state => {
      if (state.insertDt === undefined || state.insertDt === null || state.insertDt === '') {
        const jsonStr = localStorage.getItem('insertDt');
        if (jsonStr === undefined || jsonStr === null) {
          state.insertDt = null;
          return state.insertDt;
        } else {
          state.insertDt = JSON.parse(jsonStr);
        }
      }
      return state.insertDt;
    },
    deleteYn: state => {
      if (state.deleteYn === undefined || state.deleteYn === null || state.deleteYn === '') {
        const jsonStr = localStorage.getItem('deleteYn');
        if (jsonStr === undefined || jsonStr === null) {
          state.deleteYn = null;
          return state.deleteYn;
        } else {
          state.deleteYn = JSON.parse(jsonStr);
        }
      }
      return state.deleteYn;
    },
  },
  mutations: {
    accessToken: (state, accessToken) => {
      if (accessToken === undefined || accessToken === null) {
        localStorage.removeItem('accessToken');
      } else {
        localStorage.setItem('accessToken', JSON.stringify(accessToken));
      }
      state.accessToken = accessToken;
    },
    companyCode: (state, companyCode) => {
      if (companyCode === undefined || companyCode === null) {
        localStorage.removeItem('companyCode');
      } else {
        localStorage.setItem('companyCode', JSON.stringify(companyCode));
      }
      state.companyCode = companyCode;
    },
    userEmail: (state, userEmail) => {
      if (userEmail === undefined || userEmail === null) {
        localStorage.removeItem('userEmail');
      } else {
        localStorage.setItem('userEmail', JSON.stringify(userEmail));
      }
      state.userEmail = userEmail;
    },
    insertDt: (state, insertDt) => {
      if (insertDt === undefined || insertDt === null) {
        localStorage.removeItem('insertDt');
      } else {
        localStorage.setItem('insertDt', JSON.stringify(insertDt));
      }
      state.insertDt = insertDt;
    },
    deleteYn: (state, deleteYn) => {
      if (deleteYn === undefined || deleteYn === null) {
        localStorage.removeItem('deleteYn');
      } else {
        localStorage.setItem('deleteYn', JSON.stringify(deleteYn));
      }
      state.deleteYn = deleteYn;
    },
  },
  actions: loginStoreActions,
};
