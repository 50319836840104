export const productsStoreActions = {

  settingProductsListInMain({commit}, {products, totalCount}){

    console.log("dsfds", products)

    commit("keyword", {keyword :products.keyword })
    commit("commonCode", {commonCode  : products.commonCode })
    commit("commonName", {commonName : products.commonName })
    commit("productsList", {productsList : products.productsList })
    commit("totalCount", {totalCount: totalCount})
  },
  setCategoryTotal : function({commit}, {category}){
    commit("categoryList", {categoryList : category});

  },



}
