<template>
  <!-- Modal -->
  <div v-if="$store.getters['userModalStore/visible'].visible">
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">연동</h2>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-10" v-if="$store.getters['userModalStore/count'].count == 0">
                <div class="form-group">
                  <h4 class="control-label">API 키</h4>
<!--                  <p class="form-control" >{{apiKey}}</p>-->
                  <textarea id="apiKeyId" class="form-control"  style="display: block; height: 35px;" :value="apiKey" readonly></textarea>
                </div>

              </div>
              <div class="col-sm-10" v-if="$store.getters['userModalStore/count'].count == 1">
                <div class="form-group">
                  <h4 class="control-label">트래킹 코드</h4>
<!--                  <p class="form-control" >{{trackingCode}}</p>-->
                  <textarea id="trackingCodeId" class="form-control" style="display: block; height: 35px;" :value="trackingCode" readonly></textarea>
                </div>

              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <h4 class="control-label">&nbsp;</h4>
                  <button type="button" class="btn btn-default " @click="copyCode">복사</button>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


// Note: Vue automatically prefixes the directive name with 'v-'


import { mapGetters } from "vuex";

export default {
  name: "UserModal",
  props : ["trackingCode", "apiKey"],
  components : {

  },
  computed : {
    ...mapGetters([
      'userModalStore/count',
      'userModalStore/visible'
    ]),
  },
  methods : {
    copyCode(){

      if(this.$store.getters['userModalStore/count'].count == 0){
        document.getElementById("apiKeyId").select();

      } else {
        document.getElementById("trackingCodeId").select();

      }
      document.execCommand("copy");
    },
    closeModal(){

      this.$store.dispatch("userModalStore/setUserModal", {status : false, num : 0})
    }
  },
  created() {
  }
};
</script>

<style scoped>

</style>