<script src="http://lite.payapp.kr/public/api/payapp-lite.js"></script>

<template>
  <!-- Modal -->
  <div v-if="showModal">
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">수신 전화번호</h2>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12" >
                <div class="form-group">
                  <h4 class="control-label">전화번호</h4>
                  <input class="form-control" v-model="phoneNumber" placeholder="000-0000-0000" />
                </div>

              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="sendMessage()">전송</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal()">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


// Note: Vue automatically prefixes the directive name with 'v-'


import { mapGetters } from "vuex";

export default {
  name: "UserModal",
  props : ['showModal', 'money'],
  components : {

  },
  data(){
    return{
      phoneNumber : null,
      planPrice : 0,
    }
  },
  computed : {
    ...mapGetters([
      'getPayappModal'
    ]),
  },
  methods : {
    // sendMessage(){
    //
    // },
    sendMessage() {
      // var PayApp = "";

      // if(this.$store.state.userStore.itemPrice == 0){
      //   this.$store.state.userStore.itemPrice = 120000
      // }
      //
      var planType = "";
      if(this.money == 120000){
        planType = '베이직'
      } else if(this.money == 300000){
        planType = '프로'

      } else if(this.money == 500000){
        planType = '스페셜'

      }

      this.planPrice =this.money;



      var obj = new Object();
      obj.goodName = "플랜 결제" + planType;
      obj.price = this.money;
      obj.recvphone =  this.phoneNumber;
      obj.feedbackurl = "http://tools.frients.io/pg/receivePayApp.do";
      obj.var1 = "타입" + planType;
      obj.var2 = this.$store.getters['loginStore/companyCode'].companyCode;
      obj.buyerid =  this.$store.getters['loginStore/userEmail'].userEmail;

      console.log(obj);
      console.log(this.$store.getters['loginStore/companyCode'].companyCode + "코드코드 못들고와...??")

      //  api.payapp.kr    https://api.payapp.kr/oapi/apiLoad.html

      if(confirm("결제를 진행하시겠습니까?")) {

        this.phoneNumber = null;
  ``
        const headers = new Headers();
        headers.append( "Content-Type", "application/json");

        const request = new Request("https://api.payapp.kr/oapi/apiLoad.html",
          {
            method : "POST",
            headers,
            mode : "cors",
            cache : "default"
          })

        const res = fetch(request);
        console.log("결과 :",res)



        // this.$store
        //     .dispatch("paymentRequest", { paths: "https://api.payapp.kr/oapi/apiLoad.html", params: obj })
        //     .then((res) => {
        console.log("trigger input:", res);


        PayApp.setParam('userid','ksacademy');
        PayApp.setParam('shopname','Modupang');
        PayApp.setParam('goodname',"플랜결제 - Type" + planType );
        PayApp.setParam('price',obj.price);
        PayApp.setParam('recvphone',obj.recvphone);
        PayApp.setParam('reqaddr',1);
        PayApp.setParam('feedbackurl',"http://tools.frients.io/pg/receivePayApp.do");
        PayApp.setParam('var1',"PlanType - " + planType);
        PayApp.setParam('var2',obj.var2);
        PayApp.setParam('buyerid',obj.buyerid);
        PayApp.call();

        this.closeModal()

      }


    },


    closeModal(){
      this.showModal = false;
      this.planPrice = 0;

     // this.$store.dispatch("payModalStore/setPayappModal", {price: 0, visible : false})
    }
  },
  mounted() {
    let jsScript = document.createElement('script');
    jsScript.setAttribute('src','https://lite.payapp.kr/public/api/payapp-lite.js');
    document.head.appendChild(jsScript)
  },
  created() {
  }
};
</script>

<style scoped>

</style>