<template>
  <div>
    <div class="main-container container">
      <div class="row">
        <ul class="breadcrumb">
          <li><a href="#"><i class="fa fa-home"></i></a></li>
          <li><a href="#">계정 관리</a></li>
        </ul>
        <div class="col-sm-9" id="content">
          <h2 class="title">계정 관리</h2>
          <div>
            <div class="row">
              <!-- 회사정보 start -->
              <div class="col-sm-6">
                <div id="personal-details">
                  <legend>회사정보</legend>
                  <div class="form-group required">
                    <label class="control-label">이메일</label>
                    <input type="text" class="form-control" :value="$store.getters['loginStore/userEmail'].userEmail" disabled>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">회사명</label>
                    <input type="text" class="form-control" v-model="companyName">
                  </div>
                  <div class="form-group">
                    <label class="control-label">사업자번호</label>
                    <input type="text" class="form-control" v-model="companyLicenseNo">
                  </div>
                  <div class="form-group">
                    <label class="control-label">전화번호</label>
                    <input type="text" class="form-control" v-model="companyPhone">
                  </div>
                  <div class="form-group">
                    <label class="control-label">팩스번호</label>
                    <input type="text" class="form-control" v-model="companyFax">
                  </div>
                  <div class="form-group">
                    <label class="control-label">홈페이지</label>
                    <input type="text" class="form-control" v-model="companyHomepage">
                  </div>
                  <div class="form-group">
                    <label class="control-label">주소</label>
                    <input type="text" class="form-control" v-model="companyAddress">
                  </div>
                </div>
                <div class="buttons clearfix">
                  <div class="pull-right">
                    <input type="button" class="btn btn-md btn-primary" value="저장" @click="saveProfile">
                  </div>
                </div>
                <br>
              </div>
              <!-- 회사정보 end -->
              <!-- 비밀번호 변경 start -->
              <div class="col-sm-6">
                <div>
                  <legend>비밀번호 변경</legend>
                  <!--                  <div class="form-group">-->
                  <!--                    <label class="control-label">기존 비밀번호</label>-->
                  <!--                    <input type="password" class="form-control" v-model="oldPass">-->
                  <!--                  </div>-->
                  <div class="form-group required">
                    <label class="control-label">새 비밀번호</label>
                    <input type="password" class="form-control" v-model="newPass1">
                    <span v-if="newPass1 != null && newPass1.length < 8"  style="color: red;">비밀번호는 8자 이상입니다.</span>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">새 비밀번호 확인</label>
                    <input type="password" class="form-control" v-model="newPass2">
                    <span v-if="newPass2 != null && newPass1 != newPass2" style="color: red;">비밀번호를 확인해주세요.</span>
                  </div>
                </div>
                <div class="buttons clearfix">
                  <div class="pull-right">
                    <input type="button" class="btn btn-md btn-primary" value="저장" @click="changePassword">
                  </div>
                </div>
              </div>
              <!-- 비밀번호 변경 end -->
            </div>
          </div>
        </div>

        <!-- 카테고리 start -->
        <side-menu />
        <!-- 카테고리 end -->

      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/views/common/SideMenu";
import { mapGetters } from "vuex";


export default {
  name: "ProfilePage",
  components: { SideMenu },
  data() {
    return {
      companyName: null,
      companyLicenseNo: null,
      companyPhone: null,
      companyFax: null,
      companyHomepage: null,
      companyAddress: null,
      userPw: null,

      oldPass: null,
      newPass1: null,
      newPass2: null,

      companyCode :  this.$store.getters["loginStore/companyCode"].companyCode,
    };
  },
  computed: {
    ...mapGetters([
      'loginStore/companyCode',
      'loginStore/userEmail'
    ]),

  },
  methods: {
    saveProfile() {
      console.log("saveProfile");

      if (this.companyName == null) {
        alert("회사명을 입력해주세요.")
        return;
      }

      var param = {
        companyCode: this.companyCode,
        userEmail: this.$store.getters['loginStore/userEmail'].userEmail,
        companyName: this.companyName,
        companyLicenseNo: this.companyLicenseNo,
        companyPhone: this.companyPhone,
        companyHomepage: this.companyHomepage,
        companyAddress: this.companyAddress,

      }

      this.api.updateUserProfile(param)
        .then(() => {
          alert("저장되었습니다.");
        })

    },
    changePassword() {
      console.log("changePassword");

      if (this.newPass1 == null) {
        alert("비밀번호를 입력해주세요.")
        return;
      }

      if (this.newPass2 == null) {
        alert("비밀번호를 입력해주세요.")
        return;
      }

      if (this.newPass2 != this.newPass1) {
        alert("비밀번호를 확인해주세요.")
        return;
      }


      var param = {
        companyCode: this.$store.getters["loginStore/companyCode"].companyCode,
        userEmail: this.$store.getters["loginStore/userEmail"].userEmail,
        userPw: this.newPass1

      }

      this.api.updateUserProfile(param)
        .then(() => {
          alert("저장되었습니다.");
        })

    }
  },
  created() {
    console.log("ProfilePage.created");

    var param = {
      companyCode: this.$store.getters['loginStore/companyCode'].companyCode
    };

    console.log(param)

    this.api.getUserProfile(param)
      .then((res) => {
        console.log("getUserProfile res : ", res);


        if (this.utils.preApiHandle(res)) {
          console.log("util : ", res);
        }


        if (res["code"] == 0) {
          this.companyName = res.company.companyName;
          this.companyLicenseNo = res.company.companyLicenseNo;
          this.companyPhone = res.company.companyPhone;
          this.companyFax = res.company.companyFax;
          this.companyHomepage = res.company.companyHomepage;
          this.companyAddress = res.company.companyAddress;
          this.userPw = res.company.userPw;
        }
      });

  },
  mounted() {
    console.log("ProfilePage.mounted");
  },
  beforeDestroy() {
    console.log("ProfilePage.beforeDestroy");
  }
};
</script>
