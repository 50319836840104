<template>
  <div>
    <div class="main-container container" style=" margin-bottom: 5pc; ">
      <ul class="breadcrumb">
        <li><router-link to="/"><i class="fa fa-home"></i></router-link></li>
        <li><a href="javascript:void(0);">플랜</a></li>
      </ul>
      <div class="row">

        <div class="col-sm-9" id="content">
          <div class="col-sm-4" v-for="(plan, index) in planList" :key="index">
            <div style="margin-right: 3px; margin-left: 3px;">
              <div class="row">
                <div class="featured-box well text-center">
                  <h3>{{ plan.planName }}</h3>
                  <p class="job-member">월 {{ setPrice(plan.planPrice) }}원
<!--                    / {{ plan.adjustTerm-->
<!--                    }}{{ setTermUnit(plan.adjustTermUnit) }}-->
                  </p>
                  <div class="planInside" v-html="checkTag(index)"></div>
                  <div v-if="plan.planName == '베이직'">상품등록/1건<br>랜덤노출<br>SNS홍보/500회<br>메세지발송/1회<br></div>
                  <div v-if="plan.planName == '프로'">상품등록/3건<br>랜덤노출<br>SNS홍보/2,000회<br>메세지발송/1회<br></div>
                  <div v-if="plan.planName == '스페셜'">상품등록/5건<br>상위노출<br>SNS홍보/3,000회<br>메세지발송/1회<br></div>
                  <div v-if="plan.planName == '프리미엄'">상품등록/10건<br>상위노출<br>SNS홍보/5,000회<br>메세지발송/3회<br></div><p></p>

                  <!--                  <p>1{{plan.countQuery}}</p>-->
                  <!--                  <p>2{{checkTag(plan.commonTagQuery)}}</p>-->
                  <!--                  <p>3{{plan.accessQuery}}</p>-->



                  <!--          <p>Lorem ipsum dolor sit amet, con-->
                  <!--            sectetuer adipiscing elit.-->
                  <!--            Donec eros tellus, scelerisqu-->
                  <!--            nec, rhonc us eget, sollicitudin-->
                  <!--            eu, vehicula venenatis, tem-->
                  <!--            por vitae, est. Praesent-->
                  <!--            vitae dui.</p>-->
                  <a href="#" class="btn btn-warning" @click="toPlanBtn(plan.planPrice)">결제하기</a>
                </div>
              </div>


            </div>
          </div>

        </div>





        <!--        <div class="col-sm-9" v-for="(plan, index) in planList" :key="index">-->
        <!--          <div>-->
        <!--            <div class="row">-->
        <!--              <div class="featured-box well text-center">-->
        <!--                <h3>{{ plan.planName }}</h3>-->
        <!--                <p class="job-member">₩{{ setPrice(plan.planPrice) }} / {{ plan.adjustTerm-->
        <!--                  }}{{ setTermUnit(plan.adjustTermUnit) }}</p>-->
        <!--                &lt;!&ndash;          <p>Lorem ipsum dolor sit amet, con&ndash;&gt;-->
        <!--                &lt;!&ndash;            sectetuer adipiscing elit.&ndash;&gt;-->
        <!--                &lt;!&ndash;            Donec eros tellus, scelerisqu&ndash;&gt;-->
        <!--                &lt;!&ndash;            nec, rhonc us eget, sollicitudin&ndash;&gt;-->
        <!--                &lt;!&ndash;            eu, vehicula venenatis, tem&ndash;&gt;-->
        <!--                &lt;!&ndash;            por vitae, est. Praesent&ndash;&gt;-->
        <!--                &lt;!&ndash;            vitae dui.</p>&ndash;&gt;-->
        <!--                <a href="#" class="btn btn-warning">구매하기</a>-->
        <!--              </div>-->
        <!--            </div>-->


        <!--          </div>-->
        <!--        </div>-->

        <side-menu />
        <payment-modal :showModal="visible" :money="price"/>

      </div>
    </div>


  </div>

</template>

<script>
import SideMenu from "@/views/common/SideMenu";
import PaymentModal from "@/components/common/PaymentModal";


export default {
  name: "PlanPage",
  components : {
    SideMenu,
    PaymentModal
  },
  data() {
    return {
      planList: [],
      visible : false,
      price : 0,
    };
  },
  methods: {
    checkTag(index){

      var crack = this.planList[index].commonTagQuery.split(',');
      var crackCount = this.planList[index].countQuery.split(',');

      if(crack != null) {

        var empth = '<p></p>';
        // 상품등록/{count} 건

        // {user} 유저 <br>

        for (var i = 0; i < crack.length; i++) {
          console.log(crack[i])
          if (crack[i] == 'PLAN_AUTO2') {
            empth = empth.replace('{user}', crackCount[i])

          } else if (crack[i] == 'PLAN_AUTO') {
            empth = empth.replace('{count}', crackCount[i])

          }
        }

        console.log(index)

        return empth;
      }



    },
    toPlanBtn(price){
      console.log("plan btn" + price)

      if(this.visible){
        this.visible = false;
      } else {
        this.visible = true;

      }

      this.price = price;

      //this.$store.state['payModalStore'].price = price;
      //this.$store.state.payModalStore.visible = !this.$store.state.payModalStore.visible;
      //console.log(this.$store.state.payModalStore.visible)
      //this.$store.state['payModalStore'].payAppPrice = price;

       //this.$store.dispatch("payModalStore/setPayappModal", {price: price, visible : status})
      //
       //this.$store.dispatch("payModalStore/settingModalStatu", {num : 1, visible : true});

    },
    setPrice(p) {
      var price = p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return price;
    },
    setTermUnit(tu) {
      var termUnit = "";

      switch (tu) {
        case "YEAR":
          termUnit = "년";
          break;
        case "MONTH":
          termUnit = "달";
          break;
        case "DAY":
          termUnit = "일";
          break;
      }

      return termUnit;
    }
  },
  created() {
    console.log("PlanPage.created");

    // if(this.$store.state.login.companyCode != null){
    //
    // }
    var param = {};
    this.api.getPlanList(param)
      .then((res) => {
        console.log("getPlanList res : ", res);
        if (res["code"] == 0) {
          this.planList = res.planList;
        }
      });

  },
  mounted() {
    console.log("PlanPage.mounted");
  },
  beforeDestroy() {
    console.log("PlanPage.beforeDestroy");
  }
};
</script>
